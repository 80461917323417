const BOOKING_TYPE_MAPPINGS = {
    '1': {
        template: 'holiday-search-panel',
        description: 'Complete holiday'
    },
    '2': {
        template: 'flydrive-search-panel',
        description: 'FlyDrive'
    },
    '4': {
        template: 'hotel-search-panel',
        description: 'Hotel only'
    },
    '5': {
        template: 'car-search-panel',
        description: 'Car hire'
    },
    '6': {
        template: 'multi-centre-search-panel',
        description: 'Multi-destination'
    },
    '7': {
        template: 'tour-search-panel',
        description: 'Tour'
    },
    '8': {
        template: 'cruise-search-panel',
        description: 'Cruise'
    }
}

const LOCATION_TYPES = {
    HOTEL: "100"
}

const KEYS = {
    ESC: 27,
    TAB: 9,
    RETURN: 13,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40
}

const EVENTS = {
    GATEWAY_SELECT: "gatewaySelected",
    LOCATION_SELECT: "locationSelected",
    LOCATION_LIST_SELECT: "locationListSelected",
    LOCATION_CLEARED: "locationCleared",
    DATE_CHANGED: "dateChanged",
    PARTY_CHANGED: "partyChanged",
    TAB_CHANGE: "tabChanged",
    OPEN_SEARCH_PANEL: "OPEN_SEARCH_PANEL",
    CLOSE_SEARCH_PANEL: "CLOSE_SEARCH_PANEL",
    OPEN_DESTINATION_LIST: "OPEN_DESTINATION_LIST",
    CLOSE_DESTINATION_LIST: "CLOSE_DESTINATION_LIST",
    OPEN_FLYOUT: "OPEN_FLYOUT",
    CLOSE_FLYOUT: "CLOSE_FLYOUT"
}

const DATE_FORMAT = {
    OUTPUT: 'dd-MM-yyyy',   // parsing & formatting from mark up and datepicker
    PARSING: 'yyyy-MM-dd',  // parsing from locations api
    FLYOUTS_OUTPUT: 'E dd MMM',
    FLYOUTS_FULL_OUTPUT:'E dd MMM yyyy'
}
export { BOOKING_TYPE_MAPPINGS, LOCATION_TYPES, KEYS, EVENTS, DATE_FORMAT }