var Handlebars = require('handlebars/runtime')
var templates = require('../../../../../../../target/classes/templates/assets/search-templates')
import request from '../utils/request'
import { LocationMetaData, NinePlusSubmission, PaxTotals } from '../types/types'
import FormHelperService from '../services/form-helper.service'
import Typeahead from "./typeahead"
import { EVENTS } from "../utils/constants"
import Datepicker from "./datepicker"
import { addMonths, endOfMonth, format } from "date-fns"
import { TrackingService } from "../services/tracking.service"

const DEFAULT_MAX_PAX = 9
const DEFAULT_XS_WIDTH = 768          // @todo: Globals from vhols.mobileScreenWidth ???

// Nine Plus lightbox Context & Service
export default class NinePlus {
  // Selectors & Constants
  readonly NP_LIGHTB_OVERLAY: string = '[data-nine-plus-overlay]'
  readonly NP_LIGHTB_OPENER: string = '[data-nine-plus-opener]'
  readonly NP_LIGHTB_CLOSE: string = '[data-nine-plus-close]'

  readonly NP_OVERLAY_CLASS: string = 'nineplus-lightbox'
  readonly NP_OPENER_CLASS: string = 'open'
  readonly NP_TOGGLE_CLASS: string = 'nineplus-lightb-open'

  // Settings
  readonly bookingTypeId: string
  readonly brand: string
  readonly maxPax: number = DEFAULT_MAX_PAX
  private isNinePlus: boolean = false
  private totalPax: number
  private adults: number
  private children: number

  // Lightbox
  readonly mountPoint: HTMLElement
  private overlay: HTMLElement
  private opener: HTMLElement
  private closeButton: HTMLLinkElement

  // Form steps
  private formSteps: NinePlusFormSteps

  constructor(bookingTypeId: string, brand: string, maxPax: number) {
    try {
      this.bookingTypeId = bookingTypeId
      this.brand = brand
      this.maxPax = maxPax
      this.mountPoint = document.querySelector(`[data-nine-plus-id="${bookingTypeId}"]`) as HTMLElement
      this.mountPoint && this.mountPoint.setAttribute('aria-hidden', 'true')
      this.mountPoint && this.mountPoint.setAttribute('hidden', 'true')
    } catch (e) {
      console.error('Nine Plus initialisation error', e)
    }
  }

  private init(): void {
    if (!this.mountPoint) { return }

    // Render nineplus template
    this.mount()

    this.overlay = this.mountPoint && this.mountPoint.querySelector(this.NP_LIGHTB_OVERLAY)
    this.opener = this.mountPoint && this.mountPoint.querySelector(this.NP_LIGHTB_OPENER)
    this.closeButton = this.overlay && this.overlay.querySelector(this.NP_LIGHTB_CLOSE)
    this.formSteps = new NinePlusFormSteps(this.overlay, this.bookingTypeId, this.brand)

    this.listen()
    this.toggle()
  }

  private mount(): void {
    if (!this.mountPoint || this.overlay) { return }

    // Update UI
    const context = {
      bookingTypeId: this.bookingTypeId,
      brand: this.brand
    }
    const partyTemplate = Handlebars.templates['nine-plus']
    const theCompiledHtml = partyTemplate(context)
    this.mountPoint.innerHTML = theCompiledHtml
  }

  private listen(): void {
    if (!this.overlay) { return }

    this.overlay.addEventListener('click', (e: Event) => {
      let target = e && e.target as HTMLElement

      if (target.getAttribute('class') && target.getAttribute('class').match(this.NP_OVERLAY_CLASS)) {
        this.toggle(false)
      }
    })

    this.closeButton.addEventListener('click', (e: Event) => {
      e && e.preventDefault()
      let target = e && e.target as HTMLElement
      this.toggle(false)
    })
  }

  private toggle(value: boolean = true): void {
    if (!this.formSteps) { return }

    if (value) {
      this.formSteps.toggle(0)

      // Tracking
      TrackingService.sendEvent({
          name: 'Nineplus Open',
          adults: this.adults,
          children: this.children
      })

      this.mountPoint && this.mountPoint.removeAttribute('aria-hidden')
      this.mountPoint && this.mountPoint.removeAttribute('hidden')
      this.overlay && this.overlay.removeAttribute('aria-hidden')
    } else {
      this.mountPoint && this.mountPoint.setAttribute('aria-hidden', 'true')
      this.mountPoint && this.mountPoint.setAttribute('hidden', 'true')
      this.mountPoint.innerHTML = ""
      this.overlay = null
    }

    FormHelperService.toggleElementClass(document.body, this.NP_TOGGLE_CLASS, value)
    FormHelperService.toggleElementClass(this.opener, this.NP_OPENER_CLASS, value)
  }

  set paxTotal(obj: PaxTotals) {
    if (!obj) { return }

    this.adults = obj.totalAdults
    this.children = obj.totalChildren

    if (this.totalPax !== obj.paxTotal) {
      this.totalPax = obj.paxTotal
      this.isNinePlus = this.totalPax > this.maxPax

      this.show()
    }
  }

  get hasNinePlus(): boolean {
    return this.isNinePlus
  }

  public show(): void {
    if (!this.isNinePlus) { return }

    if (!this.overlay) {
      this.init()
    } else {
      this.toggle()
    }
  }
}

class NinePlusFormStep {
  // Selectors & Constants
  readonly NP_FS_SUBMIT: string = 'data-nine-plus-step-submit'
  readonly NP_FS_TOGGLE_CLASS: string = 'nineplus-active'
  readonly NP_FS_SUCCESS_CLASS: string = 'nineplus-success'
  readonly NP_FS_ERROR_CLASS: string = 'nineplus-error'
  readonly SUBMISSION_TYPES = ['continue', 'send']

  // Settings
  readonly index: number
  readonly bookingTypeId: string
  readonly brand: string
  private active: boolean = false
  readonly submitCallback: any

  // Key elements
  readonly container: HTMLElement
  readonly form: HTMLFormElement
  readonly submit: HTMLElement

  private typeahead?: Typeahead
  private gateways?: HTMLSelectElement
  private datepicker?: Datepicker

  constructor(index: number, container: Node, bookingTypeId: string, brand: string, submitCallback?: any) {
    try {
      this.index = index
      this.bookingTypeId = bookingTypeId
      this.brand = brand
      this.container = container as HTMLElement
      this.form = this.container && this.container.querySelector('form') as HTMLFormElement
      this.submit = this.container && this.container.querySelector(`[${this.NP_FS_SUBMIT}]`) as HTMLElement
      this.submitCallback = submitCallback

      this.init()
      this.listen()
    } catch (e) {
      console.error('Nine Plus form step initialisation error', e)
    }
  }

  private init(): void {
    if (!this.form) { return }

    let typeaheadElement: HTMLElement = this.form.querySelector('.sp-typeahead')
    let hasTypeahead: boolean = typeaheadElement !== null
    if (hasTypeahead) {
      this.typeahead = new Typeahead(typeaheadElement, this.brand, this.bookingTypeId)
    }

    let gatewaysElement: HTMLSelectElement = this.form.querySelector('[data-gateways] select')
    let hasGateways: boolean = gatewaysElement !== null
    if (hasGateways) {
      this.gateways = gatewaysElement
    }

    let datepickerElement: HTMLElement = this.form.querySelector('[data-search-datepicker]')
    let hasDatepicker: boolean = datepickerElement !== null
    if (hasDatepicker) {
      this.datepicker = new Datepicker(datepickerElement)
    }
  }

  private listen(): void {
    if (!this.submit) { return }

    this.submit.addEventListener('click', (e: Event) => {
      e && e.preventDefault()
      let target = e && e.target as HTMLElement
      let type = target && target.getAttribute(this.NP_FS_SUBMIT)
      let isValid: boolean = (this.form && this.form.checkValidity()) || (!this.form)

      if (isValid && (this.SUBMISSION_TYPES.indexOf(type) > -1)) {
        if (this.submitCallback && typeof this.submitCallback === 'function') {
          this.submitCallback()
        }
      }
    })

    // Add form listeners
    if (this.form) {
      // Listen for location change
      this.form.addEventListener(EVENTS.LOCATION_SELECT, (e: CustomEvent) => {
        const meta: LocationMetaData = e.detail && e.detail.locationMetadata

        // Update datepicker
        if (this.datepicker && meta != null) {
          this.datepicker.updateCalendarConfiguration(meta)
        }

        // Update gateways
        if (this.gateways && meta.gateways) {
          FormHelperService.updateSelectOptions(this.gateways, meta.gateways, 'Choose an airport')
        }
      })

      // Listen for location change
      this.form.addEventListener(EVENTS.LOCATION_CLEARED, (e: CustomEvent) => {
        // Update gateways
        if (this.gateways) {
          FormHelperService.clearSelectOptions(this.gateways, 'Choose an airport')
        }
      })
    }

    FormHelperService.friendlyValidation(this.form, true)
  }

  public toggle(val: boolean = true): void {
    if (!this.container) { return }

    this.active = val
    FormHelperService.toggleElementClass(this.container, this.NP_FS_TOGGLE_CLASS, val)
  }

  public gather(): any {
    if (!this.form) { return }

    const params = Array.prototype.slice.call(this.form.elements).filter(e => e.getAttribute('name'))
    let paramsMap = {}

    for (let i = 0; i < params.length; i++) {
      let f = params[i] as HTMLFormElement
      paramsMap[`${f.name}`] = f.value
    }

    return paramsMap
  }

  public afterSubmit(hasError: boolean = false): void {
    let selector = hasError ? this.NP_FS_ERROR_CLASS : this.NP_FS_SUCCESS_CLASS
    let el = this.container && this.container.querySelector(`.${selector}`) as HTMLElement

    if (el) {
      FormHelperService.toggleElementClass(el, this.NP_FS_TOGGLE_CLASS, true)
    }
  }
}

/*
*   NinePlus form steps raw description:
*   Desktop   Mobile
*   0           0     - 'Need few details...' intro with link (mobile only) [continue]
*   0           1     - 'Travel Plans' form                                 [continue]
*   2           2     - 'Contact Details' form                              [send]
*   3           3     - 'Thank you'                                         [void]
* */
class NinePlusFormSteps {
  // Selectors & Constants
  readonly NP_REQUEST_QUOTE_STEP: number = 0
  readonly NP_TRAVEL_PLANS_STEP: number = 1
  readonly NP_CONTACT_DETAILS_STEP: number = 2
  readonly NP_THANK_YOU_STEP: number = 3

  readonly NP_REQUEST_QUOTE_CLASS: string = 'nineplus-request-quote'
  readonly NP_TRAVEL_PLANS_CLASS: string = 'nineplus-travel-plans'
  readonly NP_CONTACT_DETAILS_CLASS: string = 'nineplus-contact-form'
  readonly NP_THANK_YOU_CLASS: string = 'nineplus-thank-you'

  readonly NP_FORM_STEPS_CLASSES = [this.NP_REQUEST_QUOTE_CLASS, this.NP_TRAVEL_PLANS_CLASS, this.NP_CONTACT_DETAILS_CLASS, this.NP_THANK_YOU_CLASS]

  // Settings
  private activeStep: number = 0
  private isMobile: boolean

  // Key elements
  private formSteps: Array<NinePlusFormStep> = []

  constructor(overlayNode: Node, bookingTypeId: string, brand: string) {
    try {
      let overlay = overlayNode as HTMLElement

      for (let i = 0; i < this.NP_FORM_STEPS_CLASSES.length; i++) {
        let el = overlay && overlay.querySelector(`.${this.NP_FORM_STEPS_CLASSES[i]}`)

        let formStep = new NinePlusFormStep(i+1, el, bookingTypeId, brand,() => { this.submitCallback() })
        this.formSteps.push(formStep)
      }

      this.init()
    } catch (e) {
      console.error('Nine Plus forms steps initialisation error', e)
    }
  }

  private init(): void {
    this.isMobile = window.innerWidth < DEFAULT_XS_WIDTH
    this.onResize()
  }

  private onResize(): void {
    window.addEventListener('resize', (e) => {
      this.isMobile = window.innerWidth < DEFAULT_XS_WIDTH

      if (!this.isMobile && (this.activeStep === this.NP_REQUEST_QUOTE_STEP)) {
        this.toggle(this.NP_TRAVEL_PLANS_STEP)
      }
    })
  }

  private submitCallback(): void {
    if (!this.isMobile && (this.activeStep === this.NP_REQUEST_QUOTE_STEP)) {
      this.toggle(this.NP_CONTACT_DETAILS_STEP)
    } else if (this.activeStep === this.NP_CONTACT_DETAILS_STEP) {
      // Form Submission
      this.submit()
    } else {
      // Form Step
      let nextStep = ++this.activeStep
      this.toggle(nextStep)
    }
  }

  private submit(): void {
    let submitter = '/.rest/groupform/v1/enquiry'     // @todo: Move to constants..
    let namedParams: any = {}

    for (let i = 0; i < this.formSteps.length; i++) {
      let params = this.formSteps[i] && this.formSteps[i].gather()

      if (params) {
        namedParams = Object.assign(namedParams, params)
      }
    }

    const data: NinePlusSubmission = {
      title: namedParams.title,
      forename: namedParams.forename,
      surname: namedParams.surname,
      email: namedParams.email,
      homePhone: namedParams.homePhone,
      destination: namedParams.location,
      flyingFrom: namedParams.flyingFrom,
      when: namedParams.departureDate,
      howLong: namedParams.howLong,
      adults: namedParams.adults,
      children: namedParams.children
    }

    // Tracking
    TrackingService.sendEvent(Object.assign({ name: 'nineplus-email-submit' }, data))

    // Send data request
    request.post(submitter, data)
      .then((response) => {
        this.onResponse()
      })
      .catch((error) => {
        console.error('Nine Plus send form request error', error)
        this.onResponse(true)
      })
  }

  private onResponse(hasError: boolean = false): void {
    this.toggle(this.NP_THANK_YOU_STEP)
    this.formSteps[this.NP_THANK_YOU_STEP] && this.formSteps[this.NP_THANK_YOU_STEP].afterSubmit(hasError)
  }

  public toggle(stepIndex: number): void {
    if (!this.formSteps) { return }

    for (let i = 0; i < this.formSteps.length; i++) {
      let active = (stepIndex === i)
      this.formSteps[i] && this.formSteps[i].toggle(active)

      if (active) {
        this.activeStep = stepIndex
      }
    }

    // Show 'Travel Plans' [1] form on Desktop
    if (!this.isMobile && (stepIndex <= this.NP_TRAVEL_PLANS_STEP)) {
      this.formSteps[this.NP_TRAVEL_PLANS_STEP] && this.formSteps[this.NP_TRAVEL_PLANS_STEP].toggle()
    }

    // Hide 'Request a quote' [0] intro on Mobiles
    if (this.isMobile && (stepIndex >= this.NP_TRAVEL_PLANS_STEP)) {
      this.formSteps[this.NP_REQUEST_QUOTE_STEP] && this.formSteps[this.NP_REQUEST_QUOTE_STEP].toggle(false)
    }
  }

}
