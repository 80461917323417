var Handlebars = require('handlebars/runtime')
var templates = require('../../../../../../../target/classes/templates/assets/search-templates')
import { OptionsViewModel } from "../types/types"

export default class FormHelperService {

  // Helper method-wrapper specially for IE
  // Important Note: IE11 does not implement classList on <svg> so we're doublechecking for classList property there
  public static toggleElementClass(target: HTMLElement, className: string, value: boolean = false) {
    if (!target) { return }

    if (value) {
      target.classList && target.classList.add(className)
    } else {
      target.classList && target.classList.remove(className)
    }
  }

  public static friendlyValidation(formNode: Node, toggleIcons: boolean = false) {
    let form = formNode as HTMLFormElement

    const toggleValidityIcons = (target: HTMLElement): void => {
      if (!target) { return }

      let isPristine = target.classList.contains('pristine')

      if (!isPristine) {
        let parent = target.parentElement as HTMLElement
        let isValid = target['validity'] && target['validity'].valid

        FormHelperService.toggleElementClass(parent, isValid ? 'invalid' : 'valid', false)
        FormHelperService.toggleElementClass(parent, isValid ? 'valid' : 'invalid', true)
      }
    }

    if (form) {
      // Toggle validation icons on the inputs if requested
      if (toggleIcons) {
        form.addEventListener('input', (e: Event) => {
          let target = e && e.target as HTMLElement
          toggleValidityIcons(target)
        }, true)
      }

      form.addEventListener('blur', (e: Event) => {
        let target = e && e.target as HTMLElement
        FormHelperService.toggleElementClass(target, 'pristine', false)

        // Toggle validation icons on the inputs if requested
        if (toggleIcons) {
          toggleValidityIcons(target)
        }
      }, true)
    }
  }

  public static updateSelectOptions(target: HTMLSelectElement, values, placeholder?: string) {
    if (target && values) {
      let context: OptionsViewModel = values
      let content = {
        placeholder: placeholder,
        context: context
      }
      const template = Handlebars.templates['options-options']
      const theCompiledHtml = template(content)
      target.innerHTML = theCompiledHtml
      target.removeAttribute('disabled')
    }
  }

  public static updateRadioOptions(identifier: string, name: string, id: string, target: HTMLFieldSetElement, values: OptionsViewModel) {
    if (target && values) {
      let context: OptionsViewModel = values
      let content = {
        identifier: identifier,
        name: name,
        UID: id,
        context: context
      }
      const template = Handlebars.templates['radio-options']
      const theCompiledHtml = template(content)
      target.innerHTML = theCompiledHtml
    }
  }

  public static clearSelectOptions(target: HTMLSelectElement, message: string) {
    if (target && message) {
      target.setAttribute('disabled', 'true')
      target.innerHTML = `<option value="">${message}</option>`
    }
  }
}