var Handlebars = require('handlebars/runtime')
var templates = require('../../../../../../../target/classes/templates/assets/search-templates')
import { Location, LocationsList, ParentLocationsList } from '../types/types'
import request from '../utils/request'
import { KEYS, EVENTS, DATE_FORMAT } from '../utils/constants'
import { helpfulCompare, mapLocationData } from '../utils/helpers'
import { isAfter } from 'date-fns'
import parse from 'date-fns/parse'
import { TrackingService } from '../services/tracking.service'

export default class DestinationsList {
    private locations: LocationsList
    private parentLocations: ParentLocationsList
    private containerElement: HTMLElement
    private listButton: HTMLButtonElement
    private listPanel: HTMLElement
    private DL_CONTAINER_CLASS: string = 'sp-destinations-container'
    private PARENT_CLASS: string = '.sp-parent-content'
    private CHILD_CLASS: string = '.sp-child-content'
    private SHOW_CLASS: string = 'flyout-active'
    private ACTIVE_CLASS: string = 'sp-active'
    private OVERFLOW_CLASS: string = 'sp-list-overflow'
    private VISIBLE_CLASS: string = 'sp-list-visible'
    private BRAND: string
    private BOOKING_TYPE_ID: string
    private BOOKING_TYPE: string
    private RESULT_SIZE: number = 100

    constructor(selector: HTMLElement, brand: string, bookingTypeId: string, bookingType: string) {
        try {
            // Set up the DOM
            this.containerElement = selector
            this.listButton = this.containerElement.querySelector('[data-list-button]')
            this.listPanel = this.containerElement.querySelector('[data-list-panel]')

            // Set up settings
            this.BRAND = brand
            this.BOOKING_TYPE_ID = bookingTypeId
            this.BOOKING_TYPE = bookingType

            // Initialise
            this.init()
        } catch (e) {
            console.error("Cannot initialise destinations list.")
        }
    }

    private init(): void {
        this.listButton.addEventListener('click', (evt: Event) => {
            const flyout_open_event: Event = new Event(EVENTS.OPEN_FLYOUT)
            document.body.dispatchEvent(flyout_open_event)
            // The first request
            if (this.listPanel.children.length <= 0) {
                request.get(`/locations-public-api/refdata/POPULARDESTINATIONS`)
                    .then((response) => {
                        let popResp = response
                        request.get(`/locations-public-api/search/location?brand=${this.BRAND}&level=0&bookingType=${this.BOOKING_TYPE_ID}&resultSize=${this.RESULT_SIZE}`)
                            .then(this.clean.bind(this))
                            .then(() =>
                                this.matchDestinations(popResp)
                            )
                            .then((response) => {
                                this.parentLocations = response
                                this.render(true)
                            })
                            .catch((error) => {
                                console.error('Something went wrong in destinations list:', error)
                                this.render()
                            })
                    })
                    .catch((error) => {
                        console.error('Something went wrong in destinations list:', error)
                        this.render()
                    })
            } else {
                this.open()
            }

            // Track opened Destination list event
            TrackingService.sendEvent({
                name: 'Destinations List',
                holidayType: this.BOOKING_TYPE
            })
        })

        this.listPanel.addEventListener('click', (evt: MouseEvent) => {
            let target: HTMLElement = evt.target as HTMLElement
            let parentLocation = target.parentElement.dataset.parentLocation
            let parents = this.listPanel.querySelector(this.PARENT_CLASS)
            let children = this.listPanel.querySelector(this.CHILD_CLASS)
            let listChildren = this.listPanel.querySelector('.sp-list-children')
            let listContainer = this.listPanel.querySelector(`.${this.DL_CONTAINER_CLASS}`)

            // Clicking on a parent
            if (parentLocation) {
                let isChildren: boolean = listChildren.childElementCount > 0
                if (!isChildren) {
                    request.get(`/locations-public-api/search/location?brand=${this.BRAND}&level=1&parent=${parentLocation}&bookingType=${this.BOOKING_TYPE_ID}&resultSize=${this.RESULT_SIZE}`)
                        .then((response) => {
                            // Map cleaned response to the parents children
                            let parent: Location = this.locations.results.find(x => x.locationId === parentLocation)
                            parent.children = this.cleanBaby(response)
                            parent.children.sort((a, b) => helpfulCompare(a.index, b.index))
                        })
                        .then(() => this.renderBaby(parentLocation, listChildren))
                        .then(() => {
                            parents.classList.remove(this.ACTIVE_CLASS)
                            children.classList.add(this.ACTIVE_CLASS)
                            listContainer.classList.remove(this.OVERFLOW_CLASS)
                            listContainer.classList.add(this.VISIBLE_CLASS)
                            this.focusSelector(this.listPanel.querySelector('[data-back]'))
                        })
                        .catch((error) => {
                            console.error('Something went wrong:', error)
                        })
                }
            }

            // Selecting a child
            if (children.classList.contains('sp-active') && target.parentElement.dataset.locationId) {
                let parent: Location = this.locations.results.find(x => x.locationId === target.parentElement.dataset.parentLocationId)
                let result: Location = parent.children.find(x => x.locationId === target.parentElement.dataset.locationId)

                this.close()
                this.resetChildren()

                // If there is no child, set the result as the parent location
                if (!result) {
                    result = {
                        bookingType: this.BOOKING_TYPE,
                        locationId: parent.locationId,
                        indexedDescription: parent.indexedDescription,
                        locationDescription: parent.locationDescription,
                        locationUrlDescription: parent.locationUrlDescription,
                        locationMetadata: parent.locationMetadata
                    }
                }

                let selectEvent: CustomEvent = new CustomEvent(EVENTS.LOCATION_LIST_SELECT, {
                    bubbles: true,
                    detail: result
                })
                this.containerElement.dispatchEvent(selectEvent)
            }

            // Close panel
            if (target.hasAttribute('data-close')) {
                this.close()
            }

            // Back button
            if (target.hasAttribute('data-back')) {
                this.resetChildren()
                this.focusSelector(this.listPanel.querySelector('.sp-list-parent'))
            }

            if (target.hasAttribute('data-md-link')) {
                this.close()
                const result = {
                    target: "[booking-tab-target='multi-centre']",
                    targetTab: "[booking-tab='multi-centre']"
                }

                let selectEvent: CustomEvent = new CustomEvent(EVENTS.TAB_CHANGE, {
                    bubbles: true,
                    detail: result
                })
                this.containerElement.dispatchEvent(selectEvent)
            }
        })

        // Capture key events
        this.listPanel.addEventListener("keydown", (evt: Event) => {
            this.keyboardBindings(KEYS, evt)
        })
    }

    private clean(response): void {
        this.locations = {
            results: response.items.map(mapLocationData).filter(item => {
              const onSaleEnd = (item.locationMetadata && item.locationMetadata.onSaleEnd) || ""
              const endDate = parse(onSaleEnd, DATE_FORMAT.PARSING, new Date())

              return (onSaleEnd == null || onSaleEnd === "" || isAfter(endDate, new Date()))
            })
        }
    }

    private cleanBaby(response): Location[] {
        return response.items.map(mapLocationData).filter(item => {
          const onSaleEnd = (item.locationMetadata && item.locationMetadata.onSaleEnd) || ""
          const endDate = parse(onSaleEnd, DATE_FORMAT.PARSING, new Date())

          return (onSaleEnd == null || onSaleEnd === "" || isAfter(endDate, new Date()))
        })
    }

    private matchDestinations(popResp): ParentLocationsList {
        let popularIds = []
        let popularDestinations = []
        const parents = this.locations.results

        // Find popular destinations based on location IDs from POPULARDESTINATIONS and match against stored IDs
        popularIds = popResp.values.filter(dest => `${dest.bookingType}` === this.BOOKING_TYPE_ID)
                                    .map(dest => dest.destination)
        popularIds.forEach(element => {
            popularDestinations.push(parents.find((value: Location) => value.locationId === element))
        })

        // Null check the list
        popularDestinations = popularDestinations.filter(popDest => popDest != null)
        
        // Delete popular destinations from the originally stored list
        popularDestinations.forEach(popDest => {
            let context = []

            context.push(parents.findIndex((x: Location) => {
                return popDest.locationId === x.locationId
            }))
            context.forEach(x => parents.splice(x, 1))
        })

        // Sort remaining destinations alphabetically
        parents.sort((a, b) => helpfulCompare(a.indexedDescription, b.indexedDescription))

        // Combine destinations
        this.locations = {
            results: popularDestinations.concat(parents).map((item: Location) => {
                return {
                    bookingType: this.BOOKING_TYPE,
                    highlightedDescription: item.highlightedDescription,
                    index: item.index,
                    indexedDescription: item.indexedDescription,
                    locationDescription: item.locationDescription,
                    locationId: item.locationId,
                    locationMetadata: item.locationMetadata,
                    locationTypeId: item.locationTypeId,
                    locationUrlDescription: item.locationUrlDescription,
                    parentLocationId: item.parentLocationId
                }
            })
        }

        // Return popular destinations and other destinations separately
        return {
            brand: this.BRAND,
            bookingType: this.BOOKING_TYPE,
            popularResults: popularDestinations.map((item: Location) => {
                return {
                    locationId: item.locationId,
                    indexedDescription: item.indexedDescription,
                    locationDescription: item.locationDescription,
                    locationUrlDescription: item.locationUrlDescription
                }
            }),
            results: parents.map((item: Location) => {
                return {
                    locationId: item.locationId,
                    indexedDescription: item.indexedDescription,
                    locationDescription: item.locationDescription,
                    locationUrlDescription: item.locationUrlDescription
                }
            })
        }
    }

    private render(focus: boolean = false): void {
        let theCompiledHtml
        if (this.parentLocations) {
            theCompiledHtml = Handlebars.templates['destinations-list'](this.parentLocations)
        } else {
            theCompiledHtml = Handlebars.templates['destinations-list'](null)
        }
        this.listPanel.innerHTML = theCompiledHtml
        focus ? this.focusSelector(this.listPanel.querySelector('.flyout-title')) : null
        this.open()
    }

    private renderBaby(parentLocationId, target): void {
        let location: Location = this.locations.results.find(x => x.locationId === parentLocationId)
        const theCompiledHtml = Handlebars.templates['destinations-children-list'](location)
        target.innerHTML = theCompiledHtml
    }

    private focusSelector(selector): void {
        if (selector) {
            selector.focus()
        }
    }

    private open(): void {
        this.listPanel.classList.add(this.SHOW_CLASS)
        document.body.classList.add('sp-lock')

        document.dispatchEvent(new CustomEvent(EVENTS.OPEN_DESTINATION_LIST, {
            bubbles: true
        }))

        this.clickAway()
    }

    private close(): void {
        this.listPanel.classList.remove(this.SHOW_CLASS)
        document.body.classList.remove('sp-lock')
        this.resetChildren()

        const flyout_close_event: Event = new Event(EVENTS.CLOSE_FLYOUT)
        document.body.dispatchEvent(flyout_close_event)
        document.dispatchEvent(new CustomEvent(EVENTS.CLOSE_DESTINATION_LIST, {
            bubbles: true
        }))

        this.destroyClickAway()
    }

    private resetChildren(): void {
        let parents = this.listPanel.querySelector(this.PARENT_CLASS)
        let children = this.listPanel.querySelector(this.CHILD_CLASS)
        let listChildren = this.listPanel.querySelector('.sp-list-children')
        let listContainer = this.listPanel.querySelector(`.${this.DL_CONTAINER_CLASS}`)
        
        parents.classList.add(this.ACTIVE_CLASS)
        children.classList.remove(this.ACTIVE_CLASS)
        listContainer.classList.add(this.OVERFLOW_CLASS)
        listContainer.classList.remove(this.VISIBLE_CLASS)
        listChildren.innerHTML = ""
    }

    private addClickAway = (e: Event): void => {
        let target: any = e.target
        const closest: HTMLElement = target.closest('[data-list-panel]') || target.closest('[data-list-button]')
        if (closest != undefined) return
        this.close()
    }

    private clickAway(): void {
        document.addEventListener('click', this.addClickAway)
    }

    private destroyClickAway(): void {
        document.removeEventListener('click', this.addClickAway)
    }

    private keyboardBindings(keys, evt) {
        switch (evt.which) {
            case keys.ESC:
                this.close()
                break
            default:
                return
        }
    }
}
