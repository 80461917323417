import { range, orDefault } from '../utils/helpers'
import { PaxTotals } from "../types/types"

// @todo: move separately to party constants or keep here
const DEFAULT_INFANTS_AGE = 1
const DEFAULT_MINORS_AGE = ""
const DEFAULT_ADULTS_NUM = 2

// Minor is the term used to represent a passenger that is either a child or an infant
// If a minor doesn't have an age, then treat it as a child
class Minor {
  constructor(
    public id: number,
    public age: number | string = DEFAULT_MINORS_AGE
  ) {}
}

// Room Instance
class Room {
  // Visible UI properties of the Room Instance
  readonly roomNumber: number
  public adults: number
  public minors: Array<Minor>

  // Some measurable properties
  private children: Array<Minor>
  private numberOfMinors: number
  private numberOfChildren: number
  private numberOfInfants: number

  // Output party string
  public party: string

  constructor(idx, numberOfAdults?, numberOfMinors?) {
    function createMinors(number) {
      return range(0, number, function(idx) {
        return new Minor(idx)
      })
    }

    this.roomNumber = idx
    this.adults = numberOfAdults || DEFAULT_ADULTS_NUM
    this.minors = createMinors(orDefault(numberOfMinors, []))

    // Do Room calculations
    this.calculateMinors()
  }

  private getMinors(minors, minorCondition) {
    return minors.filter(function(minor) {
      return minorCondition(minor)
    })
  }

  public countMinors(minors, minorCondition) {
    return this.getMinors(minors, minorCondition).length
  }

  public static childCondition(minor) {
    return minor.age !== DEFAULT_INFANTS_AGE
  }

  public static infantCondition(minor) {
    return minor.age === DEFAULT_INFANTS_AGE
  }

  public static noAgeCondition(minor) {
    return minor.age === 0
  }

  public calculateMinors(): void {
    this.numberOfMinors = this.minors.length
    this.children = this.getMinors(this.minors, Room.childCondition)
    this.numberOfChildren = this.countMinors(this.minors, Room.childCondition)
    this.numberOfInfants = this.countMinors(this.minors, Room.infantCondition)

    this.calculateParty()
  }

  private getMinorParty(minorPrefix: string, minorCondition): string {
    let minors = this.getMinors(this.minors, minorCondition) || []
    let m = []

    minors.map((minor) => {
      m.push(minorPrefix + minor.age.toString())
    })

    return m.join(',')
  }

  public calculateParty(): void {
    let party = [
      'a' + this.adults.toString(),
      this.getMinorParty('c', Room.childCondition)
    ]

    if (this.numberOfInfants > 0) {
      party.push('i' + this.numberOfInfants.toString())
    }

    let values = Object.keys(party).map(e => party[e])
    this.party = values.filter((p) => p.length).join(',')
  }

  public addMinor(age?: number): void {
    this.minors.push(new Minor(this.minors.length + 1, age))
    this.calculateMinors()
  }

  public addMinors(minors): void {
    this.minors = minors;
    this.calculateMinors()
  }

  public removeMinor(): void {
    if (this.minors.pop()) {
      this.calculateMinors()
    }
  }

  public addInfant(): void {
    var minor = new Minor(this.minors.length + 1, DEFAULT_INFANTS_AGE);
    this.minors.push(minor);
  }
}

// All the handy stuff
class PartyCompositionService {
  public static defaultAdultsNumber: number = DEFAULT_ADULTS_NUM
  public static defaultMinorsAge: number | string = DEFAULT_MINORS_AGE

  public static range = range

  public static newRoom(roomNumber: number, adults?: number, minors?: number): Room {
    return new Room(roomNumber + 1, adults, minors)
  }

  public static updateMinorsInRoom(room, numberOfMinors) {
    // if the number of minors equals the amount of minors in the minors array, then this function has been called
    // due to a change in a minor age, so just request a recalculation of the sums of minors/children/infants in the party composition.
    // Otherwise remove or add a child (which also results in recalculation)

    if (room.minors.length === numberOfMinors) {
      room.calculateMinors()
    }
    else {
      while (room.minors.length > numberOfMinors) {
        room.removeMinor()
      }
      while (room.minors.length < numberOfMinors) {
        room.addMinor()
      }
    }
  }

  // check whether the minor is of infant age, and if so add as an infant
  protected static countMinorsInAllRooms(rooms: Array<Room>, minorTypeCheck): number {
    return rooms.reduce((acc, room) => {
      return acc + room.countMinors(room.minors, minorTypeCheck)
    }, 0)
  }

  public static calculatePartyCompositionTotals(rooms: Array<Room>): PaxTotals {
    let paxTotal = rooms.reduce((acc, room) => {
      return acc + room.adults + room.minors.length
    }, 0)

    let totalAdults = rooms.reduce((acc, room) => {
      return acc + room.adults
    }, 0)

    let totalNoAges = PartyCompositionService.countMinorsInAllRooms(rooms, Room.noAgeCondition)

    let totalChildren = PartyCompositionService.countMinorsInAllRooms(rooms, Room.childCondition)

    let totalInfants = PartyCompositionService.countMinorsInAllRooms(rooms, Room.infantCondition)

    let totalMinors = totalChildren + totalInfants

    return {
      paxTotal: paxTotal,
      totalAdults: totalAdults,
      totalMinors: totalMinors,
      totalChildren: totalChildren,
      totalInfants: totalInfants,
      totalNoAges: totalNoAges,
      rooms: rooms.length
    }
  }

}

export default PartyCompositionService