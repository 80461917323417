import { Location, LocationMetaData, OptionViewModel } from "../types/types"
import { LOCATION_TYPES } from "../utils/constants"

// Handy helpers go here

const indexGenerator = function (idx) {
  return idx;
}

export const range = function(start, end, generator) {
  let values = [], idx;
  generator = generator || indexGenerator

  for (idx = start; idx < end; idx++) {
    values.push(generator(idx, start, end))
  }

  return values;
}

export const orDefault = function(value, defaultValue) {
  return value != null ? value : defaultValue
}

export const cleanMetaData = function (location) : LocationMetaData {
  let meta: LocationMetaData = {
    onSaleStart: location.locationMetadata.onSaleStart || "",
    onSaleEnd: location.locationMetadata.onSaleEnd || "",
    durationValue: parseInt(location.locationMetadata.durationValue, 10),
    durationUnit: location.locationMetadata.durationUnit || ""
  }

  if (location.locationMetadata.gateway && location.locationMetadata.gateway.length > 0) {
    const sortedGateways = location.locationMetadata.gateway.slice().filter(gateway => {
      return gateway.enabled === true
    })
    sortedGateways.sort((f, s) => {
      const fIndex = f.index != null ? f.index : 1000
      const sIndex = s.index != null ? s.index : 1000
      return fIndex == sIndex ? 0 : fIndex < sIndex ? -1 : 1
    })
    let selectedGateway: string = sortedGateways[0].code || ""
    meta.gateways = {
      options: sortedGateways.map(m => {
        let gateway: OptionViewModel = {
          value: m.code,
          description: m.description
        }
        return gateway
      }),
      selectedValue: selectedGateway
    }
  }

  if (location.details && location.details.openingTimes) {
    meta.openingTimes = location.details.openingTimes
  }

  return meta
}

export const mapLocationData = (data): Location => {
  if (!data) { return }

  let result: Location = {
    locationId: data.locationId,
    index: data.index,
    highlightedDescription: data.highlightedDescription ? data.highlightedDescription[0] || data.highlightedDescription : data.indexedDescription,
    locationTypeId: data.locationTypeId,
    parentLocationId: data.parentLocationId,
    indexedDescription: data.indexedDescription,
    locationDescription: data.locationDescription,
    locationUrlDescription: data.locationUrlDescription,
    locationMetadata: cleanMetaData(data)
  }

  if (data.locationTypeId === LOCATION_TYPES.HOTEL) {
    result['parentLocationUrlDescription'] = data.parentLocationUrlDescription
  }

  return result
}

export const helpfulCompare = function(a, b): number {
  if (a < b) {
      return -1
  }
  if (a > b) {
      return 1
  }
  return 0
}

const ARRAY: any = (Array as any)

export function asArray(arr: any): Array<any> {
  if (arr != null) {
    return Array.prototype.slice.call(arr)
  }

  return []
}
