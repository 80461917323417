import request from '../utils/request'
import { EVENTS } from '../utils/constants'
import { Location } from '../types/types'
import { mapLocationData } from '../utils/helpers'

export default class LocationDropdown {
    private locationElement: HTMLSelectElement
    private BOOKING_TYPE_ID: string
    private BRAND: string
    public selectedValue: string

    constructor(element: HTMLSelectElement, brand: string, bookingTypeId: string) {
        try {
            // Set up DOM
            this.locationElement = element

            // Set up settings
            this.BOOKING_TYPE_ID = bookingTypeId
            this.BRAND = brand

            // Initialise
            this.init()
        } catch(e) {
            console.error('Location Dropdown cannot be initialised')
        }
    }

    private init() {
        this.locationElement.addEventListener('change', (e: Event) => {
            this.selectedValue = e.target.value
            this.update(e.target.value)
        })
    }

    private update(q: string) {
        // Locations API request
        let query = `/locations-public-api/search/location/${q}?bookingType=${this.BOOKING_TYPE_ID}&brand=${this.BRAND}`

        request.get(query)
            .then(mapLocationData)
            .then((result: Location) => {
                let selectEvent: CustomEvent = new CustomEvent(EVENTS.LOCATION_SELECT, {
                    bubbles: true,
                    detail: result
                })
                this.locationElement.dispatchEvent(selectEvent)
            })
            .catch(error => {
                console.error('Error in locations dropdown selection', error)
            })
    }
}
