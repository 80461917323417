import { TrackingService } from "../services/tracking.service"
import { EVENTS } from '../utils/constants'

export default class Tabs {
    private container: HTMLElement
    private buttons: NodeList
    private tabs: NodeList
    private list: Element
    private multiLink: Element
    private wrapper: Element
    private ACTIVE_BUTTON_CLASS: string = 'sp-active'
    private ACTIVE_TAB_CLASS: string = 'sp-selected'
    private INTERSECTING_CLASS: string = 'sp-tab-intersecting'

    constructor(container: HTMLElement) {
        try {
            // Set up DOM elements
            this.container = container

            this.buttons = this.container.querySelectorAll('[booking-tab-target]')
            this.tabs = this.container.querySelectorAll('[booking-tab]')
            this.list = this.container.querySelector('.booking-tabs-list')
            this.wrapper = this.container.querySelector('.sp-booking-type-tabs')
            this.multiLink = this.container.querySelector('[data-md-link]')

            // Initialise
            this.init()
        } catch (e) {
            console.error('Tabs cannot be initialised', e)
        }
    }

    public select(target: HTMLButtonElement): void {
        let targetType: string = target.getAttribute('booking-tab-target')
        let targetTab: HTMLElement = this.container.querySelector(`[booking-tab=${targetType}]`)

        this.activate(target, targetTab)

        TrackingService.sendEvent({
          name: 'Holiday Type Tab Change',
          holidayType: targetType
        })

        // Focus on the 1st input
        //targetTab.focus()
    }

    public activate(target: HTMLButtonElement, targetTab: HTMLElement): void {
        // Clear active button
        let activeButton = this.container.querySelector(`.${this.ACTIVE_BUTTON_CLASS}`)
        if (activeButton) {
            activeButton.classList.remove(this.ACTIVE_BUTTON_CLASS)
        }

        // Set new active button
        target.parentElement.classList.add(this.ACTIVE_BUTTON_CLASS)

        // Clear active tab
        let activeTab = this.container.querySelector(`.sp-tab.${this.ACTIVE_TAB_CLASS}`)
        if (activeTab) {
            activeTab.classList.remove(this.ACTIVE_TAB_CLASS)
        }
        // Set new active tab
        targetTab.classList.add(this.ACTIVE_TAB_CLASS)
    }

    private init(): void {

        document.querySelector('.sp-tab-container').querySelector('.sp-active').scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })

        const config = {
            root: this.container,
            rootMargin: '0px',
            threshold: 0
        };

        let observer = new IntersectionObserver((entries: IntersectionObserverEntry[], SELF) => {
            entries.forEach((entry: IntersectionObserverEntry) => {
                if (entry.isIntersecting) {
                    this.wrapper.classList.remove(this.INTERSECTING_CLASS)
                } else {
                    this.wrapper.classList.add(this.INTERSECTING_CLASS)
                }
            })
        }, config);

        let thing = document.querySelector('.sp-marker')

        observer.observe(thing)

        for (let i = 0; i < this.buttons.length; i++) {
            this.buttons[i].addEventListener('click', (e: Event) => {
                this.select(<HTMLButtonElement>this.buttons[i])
            })
        }

        this.container.addEventListener(EVENTS.TAB_CHANGE, (e: CustomEvent) => {
            let target = this.container.querySelector(e.detail.target)
            let targetTab = this.container.querySelector(e.detail.targetTab)
            this.activate(target, targetTab)
        })

        this.multiLink && ['touchstart', 'click'].forEach((eventName: string) => this.multiLink.addEventListener(eventName, (e: Event) => {
            this.activate(this.container.querySelector('[booking-tab-target="multi-centre"]'), this.container.querySelector('[booking-tab="multi-centre"]'))
        }))
    }
}