// Helper classes to initialise custom styled number increase/decrease input from PL

import FormHelperService from "../services/form-helper.service";

class NumberedInput {
  // Key elements
  readonly input: HTMLInputElement
  readonly incBtn: HTMLButtonElement
  readonly decBtn: HTMLButtonElement

  // Settings
  private minVal: number
  private maxVal: number
  private step: number

  readonly NUM_PAD_SUBTRACT: number = 48

  constructor(inputContainer: HTMLElement) {
    try {
      this.input = inputContainer.querySelector('input')
      this.incBtn = inputContainer.querySelector('[inc-button]')
      this.decBtn = inputContainer.querySelector('[dec-button]')

      this.init()
      this.listen()
    } catch (e) {
      console.log('Number input initialise error', e)
    }
  }

  private init(): void {
    if (!this.input) { return }

    this.minVal = parseInt(this.input.min, 10)
    this.maxVal = parseInt(this.input.max, 10)
    this.step = parseInt(this.input.step, 10) || 1
  }

  private listen(): void {
    // Decrement button listener
    if (this.input != undefined && this.decBtn != undefined) {
      this.decBtn.addEventListener('click', (e) => {
        e && e.preventDefault()
        let value = parseInt(this.input.value, 10)
        value = isNaN(value) ? 0 : value

        if (!isNaN(this.minVal) && value <= this.minVal) { return }

        // Change and apply the value
        value -= this.step
        this.input.value = value.toString()

        // Dispatch input change event
        this.inputChange()

        // Check inc/dec buttons disabled states
        this.checkSteps(value)
      })
    }

    // Increment button listener
    if (this.input != undefined && this.incBtn != undefined) {
      this.incBtn.addEventListener('click', (e) => {
        e && e.preventDefault()
        let value = parseInt(this.input.value, 10)
        value = isNaN(value) ? 0 : value

        if (!isNaN(this.maxVal) && value >= this.maxVal) { return }

        // Change and apply the value
        value += this.step
        this.input.value = value.toString()

        // Dispatch input change event
        this.inputChange()

        // Check inc/dec buttons disabled states
        this.checkSteps(value)
      })
    }

    // Input keydown listener
    this.input && this.input.addEventListener('keydown', (e) => {
      e && e.preventDefault()

      const key = e.keyCode || e.which
      let value: string = (!isNaN(this.minVal) && this.minVal.toString()) || '0'

      // Copy/Paste event
      if (key == 67 || key == 86) {
        e && e.stopPropagation()
      }

      // Digit & number (top & side num) keys
      if ((key >= 48 && key <= 57)) {
        value = String.fromCharCode(key)
      } else if (key >= 96 && key <= 105) {
        value = String.fromCharCode(key - this.NUM_PAD_SUBTRACT)
      }

      let numValue = parseInt(value, 10)

      // Check & set for min/max values
      if (!isNaN(this.minVal) && numValue < this.minVal) {
        numValue = this.minVal
      } else if (!isNaN(this.maxVal) && numValue > this.maxVal) {
        numValue = this.maxVal
      }

      // Apply the value
      this.input.value = numValue.toString()

      // Dispatch input change event
      this.inputChange()

      // Check inc/dec buttons disabled states
      this.checkSteps(numValue)

    })
  }

  private inputChange(): void {
    const event = document.createEvent("HTMLEvents")
    event.initEvent("change", false, true)
    this.input.dispatchEvent(event)
  }

  private checkSteps(value: number): void {
    const decDisabled = !isNaN(this.minVal) && (value - this.step) < this.minVal
    FormHelperService.toggleElementClass(this.decBtn, 'disabled', decDisabled)

    const incDisabled = !isNaN(this.maxVal) && (value + this.step) > this.maxVal
    FormHelperService.toggleElementClass(this.incBtn, 'disabled', incDisabled)
  }
}

export default class NumberInputs {
  readonly container: HTMLElement
  readonly numberInputContainers: HTMLCollectionOf<HTMLElement>

  constructor(container: HTMLElement) {
    this.container = container
    this.numberInputContainers = this.container.getElementsByClassName('sp-number-input') as HTMLCollectionOf<HTMLElement>
    this.init()
  }

  private init(): void {
    if (!this.numberInputContainers) { return }

    for (let i = 0; i < this.numberInputContainers.length; i++) {
      const div: HTMLElement =  this.numberInputContainers[i]
      const numInput = new NumberedInput(div)
    }
  }
}