require('es6-promise/auto')
var Handlebars = require('handlebars/runtime')
import * as helpers from './helpers'
import Tabs from './components/tabs'
import SearchForm from './components/search-form'
import { EVENTS } from './utils/constants'

// Dynamic polyfill loader
function importPolyfills() {
    let promises = [];

    if (!Array.prototype.find) {
        promises.push(import("./polyfills/array.find"));
    }

    if (!Array.prototype.findIndex) {
        promises.push(import("./polyfills/array.findIndex"));
    }

    if (!Array.prototype.forEach) {
        promises.push(import("./polyfills/array.forEach"));
    }

    if (window.NodeList && !NodeList.prototype.forEach) {
        promises.push(import("./polyfills/nodelist.forEach"));
    }

    if (typeof window.CustomEvent !== "function") {
        promises.push(import("./polyfills/customevent"));
    }

    if (!String.prototype.includes) {
        promises.push(import("./polyfills/string.includes"));
    }

    if (!Element.prototype.matches) {
        promises.push(import("./polyfills/matches"));
    }

    if (!Element.prototype.closest) {
        promises.push(import("./polyfills/closest"));
    }

    if (!('IntersectionObserver' in window)) {
        promises.push(require("./polyfills/intersection-observer"));
    }

    return Promise.all(promises);
}

importPolyfills()

document.addEventListener("DOMContentLoaded", () => {

    if ((<any>window).spLoaded) {
        return
    }
    (<any>window).spLoaded  = true

    // get the container
    const container = document.getElementById('search-panel')
    if (container) {
        // Set up the booking tabs
        new Tabs(container)
    }

    // Set up the form
    let forms: NodeList = document.querySelectorAll('.sp-form')
    for (let i = 0; i < forms.length; i++) {
        new SearchForm(forms[i])
    }

    // Close button
    const closeButton = document.querySelector('.sp-close-button')
    if (closeButton) {
        closeButton.addEventListener('click', (e: Event) => {
            const closeEvent: Event = new Event(EVENTS.CLOSE_SEARCH_PANEL)
            document.body.dispatchEvent(closeEvent)
        })
    }

    document.body.addEventListener(EVENTS.OPEN_SEARCH_PANEL, (e: CustomEvent) => {
        document.body.classList.add('search-panel-takeover')
    });

    document.body.addEventListener(EVENTS.CLOSE_SEARCH_PANEL, (e: CustomEvent) => {
        document.body.classList.remove('search-panel-takeover')
    });
})