var Handlebars = require('handlebars/runtime')
var templates = require('../../../../../../../target/classes/templates/assets/search-templates')
import PartyCompositionService from "../services/party-composition.service"
import FormHelperService from "../services/form-helper.service"
import NinePlus from "./nine-plus"
import { TrackingService } from "../services/tracking.service"
import { PaxTotals } from "../types/types"
import NumberInputs from "./number-inputs"
import { EVENTS } from "../utils/constants"

class Option {
  constructor(public value: number, public description: number | string) {}
}

class Select {
  constructor(public options: Array<Option>, public selectedValue: number | string) {}
}

const DEFAULT_ADULTS_OPTIONS = PartyCompositionService.range(1, 10, function(i) {
  return new Option(i, i)
})
const DEFAULT_CHILDREN_OPTIONS = PartyCompositionService.range(0, 9, function(i) {
  return new Option(i, i)
})
const DEFAULT_CHILDREN_AGES_OPTIONS = PartyCompositionService.range(1, 18, function(i) {
  return new Option(i, i)
})

// Helper class for Number Input party element
class PartyInput {
  readonly partyInputElement: HTMLElement
  readonly partySelectOptions: Array<Option>
  readonly onChange: () => any
  readonly isSelectInput: boolean
  private selectedValue: number | string

  constructor(input: HTMLElement, options: Array<Option>, partyCallback: () => any) {
    try {
      this.partyInputElement = input
      this.partySelectOptions = options
      this.onChange = partyCallback
      this.isSelectInput = input && (input.nodeName === 'SELECT')
      this.selectedValue = this.gatherValue(this.partyInputElement)
      this.listen()
    } catch (e) {
      console.error('Party select initialisation error', e)
    }
  }

  get value(): number | string {
    return this.selectedValue
  }

  set value(val: number | string) {
    this.selectedValue = val
  }

  private gatherValue(targetEl): number | string {
    if (!targetEl) { return }
    let value: number | string

    if (this.isSelectInput) {
      let options: HTMLOptionsCollection = targetEl && targetEl['options']
      let selectedIdx: number = targetEl && targetEl['selectedIndex']
      value = (selectedIdx !== -1) ? +options[selectedIdx].value : ""
    } else {
      value = parseInt(targetEl.value, 10)
      value = isNaN(value) ? "" : value
    }

    return value
  }

  protected listen(): void {
    if (!this.partyInputElement) { return }

    this.partyInputElement.addEventListener('change', (e: Event) => {
      // Room Party Change
      this.selectedValue = this.gatherValue(e && e.target)
      // Sync with model
      if (typeof this.onChange === 'function') { this.onChange() }
    })
  }

  public toggleValidity(isValid: boolean = true): void {
    let validity: string = isValid ? "" : "Please, enter a valid value"
    this.partyInputElement.setCustomValidity(validity)
  }
}

// Describes every party strip entry
// Common container Class Name: .sp-room
class Party {
  // Selectors
  readonly SP_PARTY_TEMPlATE: string = 'room-children-ages'

  // Key elements
  readonly container: HTMLElement
  private hiddenPartyElement: HTMLInputElement
  private childrenRoomsElement: HTMLElement
  private childrenAgesSelectElements: NodeListOf<HTMLElement>
  readonly onChange: () => any

  // View Model properties
  public adultsParty: PartyInput
  public childrenParty: PartyInput
  public childrenAgesParty: Array<PartyInput> = []

  // Context Model
  public childrenAgesContext: Array<Select> = []

  // Object Model
  public room

  constructor(container: HTMLElement, partyIdx: number, partyCallback: () => any) {
    try {
      if (!container) { return }
      this.container = container
      this.onChange = partyCallback

      // Init key elements
      let adultsInputElement: HTMLElement = container.querySelector('[data-party-adults]')
      let childrenInputElement: HTMLElement = container.querySelector('[data-party-children]')

      this.hiddenPartyElement = container.querySelector('[data-party-hidden]')
      this.adultsParty = new PartyInput(adultsInputElement, DEFAULT_ADULTS_OPTIONS, () => this.partyCallback())
      this.childrenParty = new PartyInput(childrenInputElement, DEFAULT_CHILDREN_OPTIONS, () => this.partyCallback())

      // Init number inputs
      let numInputs = new NumberInputs(this.container)

      // Init the PARTY!!
      this.initParty()
      this.initRoom(partyIdx)
    } catch (e) {
      console.error('Party initialisation error', e)
    }
  }

  private initParty(): void {
    this.childrenRoomsElement = this.container.querySelector('.sp-party-children') as HTMLElement

    if (!this.childrenRoomsElement) { return }

    this.childrenAgesSelectElements = this.childrenRoomsElement.querySelectorAll('[data-party-child-age]')
    this.childrenAgesParty = []

    // Update Context Model
    this.initContext()
  }

  private initContext(intendedContextLength?: number): void {
    if (!this.childrenRoomsElement) { return }

    const contextLength = (intendedContextLength >= 0) ? intendedContextLength : this.childrenAgesSelectElements.length
    let context = []
    let party = []

    for (let i = 0; i < contextLength; i++) {
      let partySelect = this.childrenAgesParty[i] ? this.childrenAgesParty[i]
        : new PartyInput(<HTMLElement>this.childrenAgesSelectElements[i], DEFAULT_CHILDREN_AGES_OPTIONS, () => this.partyCallback())

      // If children age element isn't rendered yet then set it as puppet with default Minors Age value
      if (!this.childrenAgesParty[i] && !this.childrenAgesSelectElements[i]) {
        partySelect.value = PartyCompositionService.defaultMinorsAge
      }

      let partySelectContext = this.childrenAgesContext[i] ? this.childrenAgesContext[i]
        : new Select(DEFAULT_CHILDREN_AGES_OPTIONS, partySelect.value)

      party.push(partySelect)
      context.push(partySelectContext)
    }

    this.childrenAgesParty = party
    this.childrenAgesContext = context
  }

  private updateContext(intendedContextLength?: number): void {
    this.childrenAgesContext = []
    this.initContext(intendedContextLength)
  }

  private partyCallback(): void {
    let children = this.childrenParty && this.childrenParty.value as number

    // Update Context Model
    this.updateContext(children)

    // Update UI only if number of children changed
    if (children !== this.room.numberOfMinors) {
      this.render()
    }

    // Update Room Object Model
    this.updateRoom()

    // Callback
    if (typeof this.onChange === 'function') { this.onChange() }
  }

  private initRoom(partyIdx): void {
    this.room = PartyCompositionService.newRoom(partyIdx)
    this.updateRoom()
  }

  private updateRoom(): void {
    this.room.adults = this.adultsParty.value
    PartyCompositionService.updateMinorsInRoom(this.room, this.childrenParty.value)

    for (let i = 0; i < this.childrenAgesParty.length; i++) {
      this.room.minors[i].age = this.childrenAgesParty[i].value
    }

    // Room calculations
    this.room.calculateMinors()
    this.hiddenPartyElement.value = this.room.party
  }

  private render(): void {
    if (!this.childrenRoomsElement) { return }

    // Update UI
    const childAgeTemplate = Handlebars.templates[this.SP_PARTY_TEMPlATE]
    const theCompiledHtml = childAgeTemplate(this.childrenAgesContext)
    const target = this.childrenRoomsElement
    target.innerHTML = theCompiledHtml

    // Update View and Context Models
    this.initParty()
  }

  public toggleValidity(isValid: boolean = true): void {
    this.adultsParty.toggleValidity(isValid)
    this.childrenParty.toggleValidity(isValid)
  }
}

// This is the main class to describe Party List component
// Common selector ID: #party-list
export default class PartyList {
  // Selectors & Constants
  private SP_PARTY_LIST_TEMPLATE: string = 'party'
  readonly SP_PARTY_MAIN_CLASS: string = 'sp-party'
  readonly SP_PARTY_ERROR_CLASS: string = 'sp-party-error-message'
  readonly SP_PARTY_ERROR_ACTIVE_CLASS: string = 'sp-party-error-active'
  readonly SP_PARTY_ERRORS = {
    childAges: '[data-party-child-ages-error]',
    infants: '[data-party-infants-error]',
    maxPax: '[data-party-max-pax-error]', // Only Vhols Tours & Cruises options and Travelplus theme
  }

  // Key elements
  readonly container: HTMLElement
  private partyStripsElements: HTMLCollectionOf<HTMLElement>

  private partyStrips: Array<Party> = []
  private partyStripsContext: any = []

  private addPartyButton: HTMLLinkElement
  private removePartyButton: HTMLLinkElement

  // Validation
  private errorMessageElement: HTMLElement
  private totals: PaxTotals
  public isValid: boolean = true

  // Settings
  readonly BOOKING_TYPE_ID: string
  readonly BRAND: string

  // Room settings
  readonly MAX_ROOMS: number = 4
  readonly MAX_PAX: number = 9

  // Validate infants per adult for: [Complete Holiday, Flydrive, MD]
  readonly INFANTS_VALIDATION_BOOKING_TYPES: Array<string> = ['1', '2', '6']

  // 9+ Settings:
  // [Complete Holiday, Flydrive, Hotel, MD] - to avoid Tours & Cruises option panels
  readonly NINE_PLUS_BOOKING_TYPES: Array<string> = ['1', '2', '4', '6']
  // Enables 9+ form - only VHOLS & only NINE_PLUS_BOOKING_TYPES
  readonly NINE_PLUS_FORM_ENABLED: boolean = false

  // Nine Plus
  private ninePlus: NinePlus

  constructor(element: Node, brand: string, bookingTypeId: string, ninePlusEnabled: boolean) {
    try {
      this.container = element as HTMLElement
      this.BOOKING_TYPE_ID = bookingTypeId
      this.BRAND = brand
      this.NINE_PLUS_FORM_ENABLED = (ninePlusEnabled && (this.NINE_PLUS_BOOKING_TYPES.indexOf(this.BOOKING_TYPE_ID) > -1))

      // Single/Multiple room templating
      this.SP_PARTY_LIST_TEMPLATE = (this.BOOKING_TYPE_ID === '2') ? 'party-composition' : 'party'

      // init the PARTY!!
      this.initParty()
    } catch (e) {
      console.error('Party list initialisation error', e)
    }
  }

  private initParty(): void {
    this.partyStripsElements = this.container && this.container.getElementsByClassName(this.SP_PARTY_MAIN_CLASS) as HTMLCollectionOf<HTMLElement>
    this.errorMessageElement = this.container && this.container.querySelector(`.${this.SP_PARTY_ERROR_CLASS}`) as HTMLElement
    this.partyStrips = []

    // Update Context Model
    this.initContext()
    this.initNinePlus()
    this.listen()
  }

  private initContext(): void {
    if (!this.partyStripsElements.length) { return }

    let context = []
    let partyStrips = []

    for (let i = 0; i < this.partyStripsElements.length; i++) {
      let party = this.partyStrips[i] ? this.partyStrips[i]
        : new Party(this.partyStripsElements[i], i, () => this.partyCallback())
      let partyContext = this.partyStripsContext[i] ? this.partyStripsContext[i]
        : {
          adults: new Select(DEFAULT_ADULTS_OPTIONS, party.adultsParty.value),
          children: {
            childCount: new Select(DEFAULT_CHILDREN_OPTIONS, party.childrenParty.value),
            ages: party.childrenAgesContext
          },
          party: party.room && party.room.party
      }

      partyStrips.push(party)
      context.push(partyContext)
    }

    this.partyStrips = partyStrips
    this.partyStripsContext = context

    this.validate()
  }

  private doTotals(): void {
    let rooms = this.partyStrips && this.partyStrips.map(party => party.room)
    this.totals = PartyCompositionService.calculatePartyCompositionTotals(rooms)
  }

  get paxTotals(): PaxTotals {
    return this.totals
  }

  private partyCallback(): void {
    this.updateContext()
    this.trackPartyChange()
    this.firePartyEvent()
  }

  private updateContext(): void {
    this.partyStripsContext = []
    this.initContext()
  }

  private add(): void {
    this.updateContext()

    if (this.partyStripsContext.length < this.MAX_ROOMS) {
      // Context Model
      let partyContext = {
        adults: new Select(DEFAULT_ADULTS_OPTIONS, PartyCompositionService.defaultAdultsNumber),
        children: {
          childCount: new Select(DEFAULT_CHILDREN_OPTIONS, 0),
          ages: []
        }
      }

      this.partyStripsContext.push(partyContext)
      this.render()
      this.trackRoomChange()
      this.firePartyEvent()
    }
  }

  private remove(): void {
    this.updateContext()

    if (this.partyStripsContext.length > 1) {
      this.partyStripsContext.pop()
      this.render()
      this.trackRoomChange(false)
      this.firePartyEvent()
    }
  }

  private trackRoomChange(isRoomAdded: boolean = true): void {
    TrackingService.sendEvent({
      name: isRoomAdded ? 'Room Added' : 'Room Removed',
      rooms: this.partyStrips.length
    })
  }

  private trackPartyChange(): void {
    if (!this.totals) { this.doTotals() }

    TrackingService.sendEvent({
      name: 'Party Changed',
      totalPax : this.totals.paxTotal,
      totalAdults : this.totals.totalAdults,
      totalChildren : this.totals.totalMinors,
      rooms: this.totals.rooms
    })
  }

  private firePartyEvent(): void {
    if (!this.totals) { this.doTotals() }

    let event: CustomEvent = new CustomEvent(EVENTS.PARTY_CHANGED, {
      bubbles: true,
      detail: Object.assign({ bookingTypeId: this.BOOKING_TYPE_ID, isValid: this.isValid }, this.totals)
    })

    this.container.dispatchEvent(event)
  }

  private render(): void {
    // Update UI
    const partyTemplate = Handlebars.templates[this.SP_PARTY_LIST_TEMPLATE]
    const theCompiledHtml = partyTemplate(this.partyStripsContext)
    const target = this.container
    target.innerHTML = theCompiledHtml

    // Update View and Context Models
    this.initParty()
  }

  private validate(): void {
    this.doTotals()

    const togglePartyStripsValidity = (isValid: boolean = true) => {
      for (let i = 0; i < this.partyStrips.length; i++) {
        this.partyStrips[i] && this.partyStrips[i].toggleValidity(isValid)
      }
    }

    // Toggle all the errors off
    this.toggleError('infants', false)
    this.toggleError('childAges', false)
    this.toggleError('maxPax', false)
    togglePartyStripsValidity()

    // Infants validation
    if ((this.INFANTS_VALIDATION_BOOKING_TYPES.indexOf(this.BOOKING_TYPE_ID) > -1) && (this.totals.totalAdults < this.totals.totalInfants)) {
      this.toggleError('infants')

      // Highlight each room block
      togglePartyStripsValidity(false)
    }

    // Children ages validation
    if (this.totals && this.totals.totalNoAges) {
      this.toggleError('childAges')
    }

    // Max pax validation
    if (this.ninePlus) {
      // 9+ lightbox for vhols: set total pax value and NinePlus class will do the logic
      this.ninePlus.paxTotal = this.totals

      if (this.ninePlus.hasNinePlus) {
        // Don't need to show the message but need to mark Party as invalid
        togglePartyStripsValidity(false)
      }
    }

    if ((this.totals.paxTotal > this.MAX_PAX) && !this.NINE_PLUS_FORM_ENABLED) {
      this.toggleError('maxPax')
    }
  }

  private toggleError(type: string, hasError: boolean = true): void {
    if (!this.errorMessageElement) { return }

    let types = Object.keys(this.SP_PARTY_ERRORS) || []

    if (types.indexOf(type) > -1) {
      let errEl = this.errorMessageElement.querySelector(this.SP_PARTY_ERRORS[type]) as HTMLElement
      FormHelperService.toggleElementClass(errEl, this.SP_PARTY_ERROR_ACTIVE_CLASS, hasError)
      this.isValid = !hasError
    }

    if (!this.NINE_PLUS_FORM_ENABLED) {
      FormHelperService.toggleElementClass(this.errorMessageElement, 'active', hasError)
    }
  }

  private initNinePlus(): void {
    if (!this.container || !this.NINE_PLUS_FORM_ENABLED) { return }

    // Don't need new instance of Nineplus after rendering events
    if (this.ninePlus instanceof NinePlus) { return }

    this.ninePlus = new NinePlus(this.BOOKING_TYPE_ID, this.BRAND, this.MAX_PAX)
  }

  public showNinePlus(): void {
    if (!this.NINE_PLUS_FORM_ENABLED || !this.ninePlus) { return }
    this.ninePlus.show()
  }

  private listen(): void {
    if (! this.container) { return }

    // Remove and don't listen party add/remove controls for Flydrive
    if (this.BOOKING_TYPE_ID === '2') {
      const partyControls = this.container.querySelector('.sp-party-controls')
      partyControls && partyControls.parentNode.removeChild(partyControls)

      return
    }

    this.addPartyButton = this.container.querySelector('[data-party-list-add]')
    this.removePartyButton = this.container.querySelector('[data-party-list-remove]')

    if (this.addPartyButton) {
      this.addPartyButton.addEventListener('click', (e: Event) => {
        // Add/Remove room event shouldn't affect Flyouts
        e.stopPropagation()
        this.add()
      })
    }
    if (this.removePartyButton) {
      this.removePartyButton.addEventListener('click', (e: Event) => {
        // Add/Remove room event shouldn't affect Flyouts
        e.stopPropagation()
        this.remove()
      })
    }
  }
}