declare let window: any;

export class TrackingService {

  public static sendEvent(eventData): void {
    var event = new CustomEvent('vhmTrackingEvent', {
      detail: eventData
    });

    window.dispatchEvent(event)
  }

  public static setUVTrackingData(key, value) {
    window.universal_variable = window.universal_variable || {};
    window.universal_variable[key] = value;
  }
}