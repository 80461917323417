var Handlebars = require('handlebars/runtime')
var templates = require('../../../../../../../target/classes/templates/assets/search-templates')
import { addDays, format, isValid, startOfDay } from 'date-fns'
import parse from 'date-fns/parse'
import { TrackingService } from "../services/tracking.service"
import { EVENTS, DATE_FORMAT, KEYS } from "../utils/constants"
import FormHelperService from "../services/form-helper.service"

export default class Flyout {
    private container: Element
    private closeButtons: NodeList
    private action: HTMLButtonElement
    private datepickerInput?: HTMLInputElement
    private durationInput?: HTMLInputElement
    private gatewayInput?: HTMLFieldSetElement
    private pickUpTimesInput?: HTMLSelectElement
    private dropOffTimesInput?: HTMLSelectElement

    constructor(container: Element) {
        try {
            // Set up DOM elements
            this.container = container

            this.action = this.container.querySelector(".flyout-action")
            this.closeButtons = this.container.querySelectorAll('[flyout-close]')

            // Grab any inputs
            let gatewaysElement: HTMLFieldSetElement = this.container.querySelector('[gateways-group]')
            let hasGateways: boolean = gatewaysElement != null
            if (hasGateways) {
                this.gatewayInput = gatewaysElement
                this.gatewayInput.addEventListener('change', (x => {
                    const value = x.target['dataset'].prettyName
                    this.update(value)

                    let gatewayChangeEvent: CustomEvent = new CustomEvent(EVENTS.GATEWAY_SELECT, {
                        bubbles: true,
                        detail: value
                    })
                    document.querySelector('.sp-form').dispatchEvent(gatewayChangeEvent)
                }))
            }

            let datepickerElement: HTMLInputElement = this.container.querySelector('.sp-datepicker-input')
            let hasDatepickers: boolean = datepickerElement != null
            if (hasDatepickers) {
                this.datepickerInput = datepickerElement
                this.datepickerInput.addEventListener('change', (x => {
                    this.updateDate()
                }))
            }

            let durationInputElement = this.container.querySelector('[name="duration"]') as HTMLInputElement
            let hasDurationInput: boolean = durationInputElement != null
            if (hasDurationInput) {
                this.durationInput = durationInputElement
                this.durationInput.addEventListener('change', (x => {
                    this.updateDate()
                }))
            }

            let pickUpTimeElement: HTMLSelectElement = this.container.querySelector('select[name="pickUpTime"]')
            let hasPickUpTime: boolean = pickUpTimeElement != null
            if (hasPickUpTime) {
                this.pickUpTimesInput = pickUpTimeElement
                this.pickUpTimesInput.addEventListener('change', (x => {
                    this.updateDate()
                }))
            }

            let dropOffTimeElement: HTMLSelectElement = this.container.querySelector('select[name="dropOffTime"]')
            let hasDropOffTime: boolean = dropOffTimeElement != null
            if (hasDropOffTime) {
                this.dropOffTimesInput = dropOffTimeElement
                this.dropOffTimesInput.addEventListener('change', (x => {
                    this.updateDate()
                }))
            }

            // Listen for Party change event
            let partyElement: HTMLElement = this.container.querySelector('[data-party-list]')
            partyElement && partyElement.addEventListener(EVENTS.PARTY_CHANGED, (x: CustomEvent) => {
                this.updateParty(x && x.detail)
            })


            // Initialise
            this.init()
        } catch (e) {
            console.error('Flyout cannot be initialised', e)
        }
    }

    private init(): void {
        this.action && this.action.addEventListener('click', (e: Event) => {
            this.open()
        })

        for (let i = 0; i < this.closeButtons.length; i++) {
            this.closeButtons[i].addEventListener('click', (e: Event) => {
                this.close()
            })
        }

        // Capture key events
        document.addEventListener("keydown", (event: KeyboardEvent) => {
            this.keyboardBindings(KEYS, event)
        })
    }

    private getPickerDate(): Date {
      let inputDate: Date = parse(this.datepickerInput.value, DATE_FORMAT.OUTPUT, new Date())

      if (!(inputDate instanceof Date && isValid(inputDate))) {
        inputDate = addDays(startOfDay(new Date()), 3)
      }

      return inputDate
    }

    public update(value: string) {
        this.action.innerHTML = value
    }

    public updateDate() {
        let date: Date = this.getPickerDate()
        let departureDate: string = format(date, DATE_FORMAT.FLYOUTS_OUTPUT)

        if (this.pickUpTimesInput) { // Car
            this.action.innerHTML = `${departureDate} ${this.pickUpTimesInput.value}`
        } else if (this.dropOffTimesInput) { // Car
            this.action.innerHTML = `${departureDate} ${this.dropOffTimesInput.value}`
        } else if (this.durationInput) { // MD
            let days = parseInt(this.durationInput.value)
            let returnDate = addDays(date, days)
            this.action.innerHTML = `${departureDate} - ${format(returnDate, DATE_FORMAT.FLYOUTS_OUTPUT)}`
        } else { // Default
            departureDate = format(date, DATE_FORMAT.FLYOUTS_FULL_OUTPUT)
            this.action.innerHTML = departureDate
        }
    }

    public updateDateTime() {
        let date: Date = this.getPickerDate()
        let departureDate: string = format(date, DATE_FORMAT.FLYOUTS_OUTPUT)

        if (this.pickUpTimesInput) {
            this.action.innerHTML = `${departureDate} - ${this.pickUpTimesInput.value}`
        } else if (this.dropOffTimesInput) {
            this.action.innerHTML = `${departureDate} - ${this.dropOffTimesInput.value}`
        }
    }

    public updateParty(details) {
        const target: HTMLElement = this.container.querySelector('[data-party-composition]')
        if (!details || !target) { return }

        // No-room templating for Flydrive, Cruise and Tour bookings
        const isSingleParty: boolean = ['2', '7', '8'].indexOf(details.bookingTypeId) > -1
        const templateName: string = isSingleParty ? 'party-composition-summary' : 'party-summary'
        const context = { contents: {
            rooms: details.rooms,
            adults: isSingleParty ? { selectedValue: details.totalAdults } : details.totalAdults,
            children: isSingleParty ? { childCount: { selectedValue: details.totalMinors } } : details.totalMinors
        }}
        const template = Handlebars.templates[templateName]
        const theCompiledHtml = template(context)
        target.innerHTML = theCompiledHtml

        // Toggle Party input validation class on SP
        if (details.isValid !== undefined) {
          FormHelperService.toggleElementClass(target, 'invalid', !details.isValid)
        }
    }

    public open() {
        const flyout_open_event: Event = new Event(EVENTS.OPEN_FLYOUT)
        document.body.dispatchEvent(flyout_open_event)
        this.closeAll()
        this.clickAway()
        this.container.classList.add('flyout-active')
        document.body.classList.add('sp-lock')
    }

    public close() {
        this.closeAll()
        const flyout_open_event: Event = new Event(EVENTS.CLOSE_FLYOUT)
        document.body.dispatchEvent(flyout_open_event)
        this.container.classList.remove('flyout-active')
        document.body.classList.remove('sp-lock')
        this.destroyClickAway()
    }

    public closeAll() {
        let allFlyouts: NodeListOf<Element> = document.querySelectorAll('.flyout-wrapper')
        for (let i = 0; i < allFlyouts.length; i++) {
            allFlyouts[i].classList.remove('flyout-active')
        }
    }

    public disable(placeholder: string) {
        this.action.setAttribute('disabled', 'disabled')
        this.action.innerText = placeholder
    }

    public enable(value?: string) {
        this.action.removeAttribute('disabled')
        if (value) {
            this.action.innerText = value
        }
    }

    private addClickAway = (e: Event): void => {
        let target: any = e.target
        const closest: HTMLElement = target.closest('.flyout-wrapper')
        if (closest != undefined) return
        this.close()
    }

    private clickAway(): void {
        document.addEventListener('click', this.addClickAway)
    }

    private destroyClickAway(): void {
        document.removeEventListener('click', this.addClickAway)
    }

    /*
    * Bind and react to keyboard events
    */
    private keyboardBindings(keys, event: KeyboardEvent) {
        switch (event.which) {
            case keys.ESC:
                this.close()
                break
            default:
                return
        }
    }
}