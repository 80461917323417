const Handlebars = require('handlebars/runtime')
const templates = require('../../../../../../../target/classes/templates/assets/search-templates')
import {addDays, addMinutes, differenceInDays, differenceInMinutes, format, getISODay} from 'date-fns'
import request from '../utils/request'
import {asArray, mapLocationData} from '../utils/helpers'
import Typeahead from './typeahead'
import DestinationsList from './destinations-list'
import PartyList from './party'
import {DATE_FORMAT, EVENTS, LOCATION_TYPES} from '../utils/constants'
import Datepicker from './datepicker'
import {Location, LocationMetaData, OpeningTime, OptionsViewModel, OptionViewModel, PaxTotals} from '../types/types'
import LocationDropdown from './location-dropdown'
import FormHelperService from "../services/form-helper.service"
import {TrackingService} from "../services/tracking.service"
import Flyout from './flyouts'

declare var SearchPanelCarOpeningTimes

export default class SearchForm {
    private bookingType: string
    private bookingTypeId: number | string
    private brand: number | string
    private form: HTMLFormElement
    private errorContainer: HTMLFormElement
    private locationCleared: boolean
    private typeaheads?: Typeahead[] = []
    private locationDropdown?: LocationDropdown
    private datepickers?: Datepicker[] = []
    private durationInput?: HTMLInputElement
    private directFlightsInput?: HTMLInputElement
    private gatewaysGroup?: HTMLFieldSetElement
    private pickUpLocation?: Location
    private pickUpTimes?: OpeningTime
    private pickUpTimeElement?: HTMLSelectElement
    private dropOffTimes?: OpeningTime
    private dropOffTimeElement?: HTMLSelectElement
    private partyComposition?: PartyList
    private flyouts?: Object = {}
    private ninePlusEnabled?: boolean
    private warningElActiveClassName = 'warning-active'
    private destinationAirport: string

    private destinationsList?: DestinationsList

    constructor(selector: Node) {
        try {
            // Set up DOM elements
            this.form = selector as HTMLFormElement
            this.errorContainer = this.form.querySelector('[data-form-error-box]')
            this.brand = this.form.dataset.brand
            this.bookingType = this.form.dataset.bookingType
            this.bookingTypeId = this.form.dataset.bookingTypeId
            this.ninePlusEnabled = (this.form.dataset.nineplusEnabled == 'true')

            // Check for flyouts
            let action = this.form.querySelectorAll(".flyout-wrapper")
            asArray(action).forEach((flyout: HTMLElement) => {
                this.flyouts[flyout.dataset.flyoutKey] = new Flyout(flyout)
            })

            // Check for optional components
            let typeaheadElements: NodeListOf<HTMLElement> = this.form.querySelectorAll('.sp-typeahead')
            let hasTypeaheads: boolean = typeaheadElements.length > 0

            if (hasTypeaheads) {
                for (let i = 0; i < typeaheadElements.length; i++) {
                    this.typeaheads.push(new Typeahead(typeaheadElements[i], this.brand, this.bookingTypeId))
                }
            }

            let locationDropdownElement: HTMLSelectElement = this.form.querySelector('[data-location-emitter]')
            let hasLocationDropdown: boolean = locationDropdownElement != null
            if (hasLocationDropdown) {
                this.locationDropdown = new LocationDropdown(locationDropdownElement, this.brand, this.bookingTypeId)
            }

            let gatewaysGroupElement: HTMLFieldSetElement = this.form.querySelector('[gateways-group]')
            let hasGatewaysGroup: boolean = gatewaysGroupElement != null
            if (hasGatewaysGroup) {
                this.gatewaysGroup = gatewaysGroupElement
            }

            let destinationsListElement: HTMLElement = this.form.querySelector('.sp-destinations-list-container')
            let hasDestinationsList: boolean = destinationsListElement != null
            if (hasDestinationsList) {
                this.destinationsList = new DestinationsList(destinationsListElement, this.brand, this.bookingTypeId, this.bookingType)
            }

            let datepickerElements: NodeListOf<HTMLElement> = this.form.querySelectorAll('[data-search-datepicker]')
            let hasDatepickers: boolean = datepickerElements.length > 0
            if (hasDatepickers) {
                for (let i = 0; i < datepickerElements.length; i++) {
                    this.datepickers.push(new Datepicker(datepickerElements[i]))
                }
            }

            let durationInputElement = this.form.querySelector('[name="duration"]') as HTMLInputElement
            let hasDurationInput: boolean = durationInputElement != null
            if (hasDurationInput) {
                this.durationInput = durationInputElement
            }

            let directFlightsInputElement = this.form.querySelector('[name="direct"]') as HTMLInputElement
            let hasDirectInput: boolean = directFlightsInputElement != null
            if (hasDirectInput) {
                this.directFlightsInput = directFlightsInputElement
            }

            let partyElement: HTMLElement = this.form.querySelector('[data-party-list]')
            let hasParty: boolean = partyElement != null
            if (hasParty) {
                this.partyComposition = new PartyList(partyElement, this.brand, this.bookingTypeId, this.ninePlusEnabled)
            }

            let pickUpTimeElement: HTMLSelectElement = this.form.querySelector('select[name="pickUpTime"]')
            let hasPickUpTime: boolean = pickUpTimeElement != null
            if (hasPickUpTime) {
                this.pickUpTimeElement = pickUpTimeElement
            }

            let dropOffTimeElement: HTMLSelectElement = this.form.querySelector('select[name="dropOffTime"]')
            let hasDropOffTime: boolean = dropOffTimeElement != null
            if (hasDropOffTime) {
                this.dropOffTimeElement = dropOffTimeElement
            }

            // Settings
            let pickUpTimesSettingsElement: HTMLScriptElement | null = this.form.querySelector(`#SearchPanelCarPickUpTimes`)
            if (pickUpTimesSettingsElement) {
                this.pickUpTimes = JSON.parse(pickUpTimesSettingsElement.innerText)
            }
            let dropOffTimesSettingsElement: HTMLScriptElement | null = this.form.querySelector(`#SearchPanelCarDropOffTimes`)
            if (dropOffTimesSettingsElement) {
                this.dropOffTimes = JSON.parse(dropOffTimesSettingsElement.innerText)
            }

            // Initialise the form
            this.init()
        } catch (e) {
            console.error('Form cannot be initialised', e)
        }
    }

    private init(): void {
        FormHelperService.friendlyValidation(this.form)

        // If car, set the max date to 28 days from departureDate
        if (this.bookingTypeId === 5) {
            let departureDate = this.datepickers[0].getDate()
            let defaultMaxDate = addDays(departureDate, 29)
            this.datepickers[1].setConfigOption("maxDate", defaultMaxDate)

            // Need to get & store Pick Up location data for cars in this scope
            // to operate with different Drop Off location behavior.

            // Pick up location value
            const locationUrlDescription = this.typeaheads[0].locationUrlDescription

            if (!locationUrlDescription) return

            // Get location data
            request.get(`/locations-public-api/search/location/${locationUrlDescription}?bookingType=${this.bookingTypeId}&brand=${this.brand}`)
                .then(mapLocationData)
                .then((result: Location) => {
                    this.pickUpLocation = result
                })
                .catch(error => {
                    console.error('Error in locations-public-api request', error)
                })
        }

        this.form.addEventListener('submit', (event: Event) => {
            event && event.preventDefault()
            const isValid = this.form.checkValidity()
            // Tracking
            this.trackSearch()

            isValid
                ? (event.currentTarget as HTMLFormElement).submit()
                : this.errorContainer.innerHTML = "Oops, something's not quite right. Please fix the below errors and try again."
        })

        // Listen for gateway selection change
        this.form.addEventListener(EVENTS.GATEWAY_SELECT, (event: CustomEvent) => {
            this.destinationAirport = event.detail
            this.toggleWarningDisplayByLocation()
        })

        // Listen for location change
        this.form.addEventListener(EVENTS.LOCATION_SELECT, (event: CustomEvent) => {
            const meta: LocationMetaData = event.detail && event.detail.locationMetadata

            this.toggleWarningDisplayByLocation()

            if (this.datepickers.length > 0 && meta != null) {
                this.datepickers[0].updateCalendarConfiguration(meta)
                // Update flyouts
                if (this.flyouts[`${this.bookingType}-date`]) {
                    this.flyouts[`${this.bookingType}-date`].updateDate()
                }
            }

            // Update gateways
            if (this.gatewaysGroup && meta.gateways) {
                // Update flyout gateways
                const gatewayOptions: OptionsViewModel = meta.gateways
                const defaultGateway: OptionViewModel = gatewayOptions.options.find(option => option.value === gatewayOptions.selectedValue)
                FormHelperService.updateRadioOptions('gateways-group', 'gateway', this.gatewaysGroup.id, this.gatewaysGroup, gatewayOptions)
                // Enable flyout
                this.flyouts['flights'] && this.flyouts['flights'].enable(defaultGateway.description)

                const gatewayDescription = defaultGateway.description;

                this.destinationAirport = gatewayDescription
                this.toggleWarningDisplayByLocation()
            }

            // Store Pick Up location and update Drop Off if different location option hasn't been selected
            if (this.isPickUp(event.target)) {
                this.pickUpLocation = event.detail
                this.typeaheads[1] && this.typeaheads[1].select(this.pickUpLocation)
            }

            // Update depot times
            if (this.pickUpTimeElement && this.isPickUp(event.target) && event.detail.locationMetadata.openingTimes) {
                this.pickUpTimes = event.detail.locationMetadata.openingTimes
                let date = this.datepickers[0].getDate()
                let times: OptionsViewModel = this.generateOpeningOptions(date, this.pickUpTimes)
                FormHelperService.updateSelectOptions(this.pickUpTimeElement, times)

                // Update & enable pick up time flyout
                if (this.flyouts['pickUpDate']) {
                    this.flyouts['pickUpDate'].updateDateTime()
                    this.flyouts['pickUpDate'].enable()
                }
            }

            if (this.dropOffTimeElement && this.isDropOff(event.target) && event.detail.locationMetadata.openingTimes) {
                this.dropOffTimes = event.detail.locationMetadata.openingTimes
                const date = this.datepickers[0].getDate()
                const times: OptionsViewModel = this.generateOpeningOptions(date, this.dropOffTimes)
                const dropOffDate = this.flyouts['dropOffDate']
                FormHelperService.updateSelectOptions(this.dropOffTimeElement, times)

                if (!dropOffDate) return
                // Update & enable drop off time flyout
                dropOffDate.updateDateTime()
                dropOffDate.enable()
            }

            // Save location field state
            this.locationCleared = false

            // Tracking
            TrackingService.sendEvent({
                name: 'Destination Change',
                holidayType: this.bookingType,
                locationType: (event.detail.locationTypeId === LOCATION_TYPES.HOTEL) ? 'Hotel' : 'Location',
                destination: this.gatherBookingDestination()
            })
        })

        // Listen for location selection from destinations list
        this.form.addEventListener(EVENTS.LOCATION_LIST_SELECT, (event: CustomEvent) => {
            if (this.typeaheads.length < 1) return
            // Update selected location
            this.typeaheads[0].select(event.detail)
        })

        // Listen for location change
        this.form.addEventListener(EVENTS.LOCATION_CLEARED, (event: CustomEvent) => {

            // remove all warning messages when locations are cleared
            Array.from(this.form.querySelectorAll('[data-warning]')).forEach(element => {
                element.classList.remove(this.warningElActiveClassName);
            })

            // Update gateways
            if (this.gatewaysGroup) {
                // Clear gateways
                this.gatewaysGroup.innerHTML = ""
                // Disable flyout
                this.flyouts['flights']?.disable('Choose an airport')
            }

            // Update depot times
            if (this.isPickUp(event.target)) {
                // Clear pick up date
                FormHelperService.clearSelectOptions(this.pickUpTimeElement, 'Time')
                // Disable flyout
                this.flyouts['pickUpDate']?.disable('Select a location')
            }

            if (this.isDropOff(event.target)) {
                // Clear drop off date
                FormHelperService.clearSelectOptions(this.dropOffTimeElement, 'Time')
                // Disable flyout
                this.flyouts['dropOffDate']?.disable('Select a location')
            }

            // Save location field state
            this.locationCleared = true
        })

        // Listen for date change
        this.form.addEventListener(EVENTS.DATE_CHANGED, (event: CustomEvent) => {

            // Update car depots opening times
            if (this.pickUpTimeElement && this.isPickUp(event.target) && this.pickUpTimes['length'] > 0) {
                let duration = this.durationInput && this.durationInput.value
                let times: OptionsViewModel = this.generateOpeningOptions(event.detail, this.pickUpTimes)
                FormHelperService.updateSelectOptions(this.pickUpTimeElement, times)

                const newDropOffDate = addDays(this.datepickers[0].getDate(), duration)
                const onSaleStart = addDays(this.datepickers[0].getDate(), 1)
                const onSaleEnd = addDays(this.datepickers[0].getDate(), 28)
                this.datepickers[1].setConfig(newDropOffDate, onSaleStart, onSaleEnd)

                // Update & enable pick up time flyout
                if (this.flyouts['pickUpDate']) {
                    this.flyouts['pickUpDate'].updateDateTime()
                    this.flyouts['pickUpDate'].enable()
                    this.flyouts['dropOffDate'].updateDateTime()
                    this.flyouts['dropOffDate'].enable()
                }
            }

            if (this.dropOffTimeElement && this.isDropOff(event.target) && this.dropOffTimes['length'] > 0) {
                let times: OptionsViewModel = this.generateOpeningOptions(event.detail, this.dropOffTimes)
                FormHelperService.updateSelectOptions(this.dropOffTimeElement, times)

                // Update & enable drop off time flyout
                if (this.flyouts['dropOffDate']) {
                    this.flyouts['dropOffDate'].updateDateTime()
                    this.flyouts['dropOffDate'].enable()
                }
            }

            // Update the duration
            let pickUpDate = this.pickUpTimeElement && this.datepickers[0].getDate()
            let dropOffDate = this.dropOffTimeElement && this.datepickers[1].getDate()

            if (pickUpDate && dropOffDate) {
                let nights = differenceInDays(dropOffDate, pickUpDate)
                let durationLabelElement = this.form.querySelector('[data-duration]') as HTMLElement

                if ((nights > 0) && durationLabelElement) {
                    let suffix = nights === 1 ? 'night' : 'nights'
                    durationLabelElement.innerHTML = `${nights} ${suffix}`
                    this.durationInput && this.durationInput?.value = nights
                }
            }

            // Disable time elements if no location selected
            if (this.locationCleared) {
                this.pickUpTimeElement?.setAttribute('disabled', 'true')
                this.dropOffTimeElement?.setAttribute('disabled', 'true')
            }

            // Tracking
            TrackingService.sendEvent({
                name: 'Departure Date Change',
                holidayType: this.bookingType,
                startDate: format(this.datepickers[0].getDate(), DATE_FORMAT.OUTPUT),
                duration: this.durationInput?.value
            })
        })

        // Duration change tracking
        this.durationInput?.addEventListener('change', (evt: Event) => {
            TrackingService.sendEvent({
                name: 'Duration Change',
                holidayType: this.bookingType,
                startDate: format(this.datepickers[0].getDate(), 'dd-MM-yyyy'),
                duration: this.durationInput?.value
            })
        })

        // Direct flights input change tracking
        this.directFlightsInput?.addEventListener('change', (evt: Event) => {
            // Tracking
            TrackingService.sendEvent({
                name: 'Direct Flight Toggle',
                holidayType: this.bookingType,
                directFlight: this.directFlightsInput.checked
            })
        })

        // Gateways Group change tracking
        this.gatewaysGroup?.addEventListener('change', (evt: Event) => {
            let selectedGateway: HTMLInputElement = this.gatewaysGroup.querySelector('input:checked')
            TrackingService.sendEvent({
                name: 'Departure Point Change',
                gateway: [selectedGateway?.value]
            })
        })

    }

    private trackSearch() {
        const partyTotals: PaxTotals = this.partyComposition && this.partyComposition.paxTotals
        let gateway: any = undefined

        if (this.gatewaysGroup) {
            const selectedGateway: HTMLInputElement = this.gatewaysGroup.querySelector('input:checked')
            gateway = selectedGateway?.value
        }

        const trackingData = {
            name: 'Search',
            holidayType: this.bookingType,
            gateway: gateway,
            destination: this.gatherBookingDestination(),
            startDate: (this.datepickers && this.datepickers[0]) ? format(this.datepickers[0].getDate(), 'dd-MM-yyyy') : undefined,
            duration: this.durationInput?.value,
            rooms: partyTotals?.rooms,
            totalPax: partyTotals?.paxTotal,
            totalAdults: partyTotals?.totalAdults,
            totalChildren: partyTotals?.totalMinors
        }

        if (this.directFlightsInput) {
            trackingData['directFlight'] = this.directFlightsInput.checked
        }

        TrackingService.sendEvent(trackingData)
    }

    private isPickUp(target: EventTarget): boolean {
        const element: HTMLElement = target as HTMLElement
        return (element.dataset.uid?.includes('pickUp'))
    }

    private isDropOff(target: EventTarget): boolean {
        let element: HTMLElement = target as HTMLElement
        return (element.dataset.uid && element.dataset.uid.includes('dropOff'))
    }

    private generateOpeningOptions(date: string | Date, openingTimes: OpeningTime): OptionsViewModel {
        const results: OptionsViewModel = {
            options: [],
            selectedValue: ""
        }
        const isoDay = getISODay(date)
        const times: OpeningTime = openingTimes.find(x => x.isoDayOfWeek == isoDay)
        const startArr = times && times.start.split(":")
        const endArr = times && times.end.split(":")
        const start = new Date().setHours(startArr && startArr[0], startArr && startArr[1], 0, 0)
        const end = new Date().setHours(endArr && endArr[0], endArr && endArr[1], 0, 0)
        const openingRangeInMinutes: number = differenceInMinutes(end, start)
        const incrementSteps: number = openingRangeInMinutes / 15
        let current = start

        for (let i = 1; i < incrementSteps; i++) {
            const nextIncrement = addMinutes(current, 15)
            results.options.push({
                value: format(nextIncrement, "HH:mm"),
                description: format(nextIncrement, "HH:mm")
            })
            current = nextIncrement
        }

        return results
    }

    private gatherBookingDestination(): string {
        let destination: string

        if (this.bookingTypeId === 5) {
            const pickUp = (this.typeaheads[0]?.locationUrlDescription) || 'None'
            const dropOff = (this.typeaheads[1]?.locationUrlDescription) || 'None'
            destination = `${pickUp} - ${dropOff}`
        } else {
            const locationInput: HTMLInputElement = this.form.querySelector('[name="location"]') as HTMLInputElement
            destination = locationInput?.value
        }

        return destination
    }

    private toggleWarningDisplayByLocation(): void {
        const ORIGINS_WHITELIST = ['London Heathrow', 'London Gatwick', 'Manchester International']

        Array.from(this.form.querySelectorAll('[data-warning]')).forEach(el => {
            el.classList.remove(this.warningElActiveClassName)
        })

        const destination =
            this.typeaheads[0]?.locationUrlDescription
            || this.typeaheads[0]?.parentName
            || this.locationDropdown.selectedValue

        if (!destination) return

        // Normalising origin name for easier html referencing (HBS file)
        const origin = this.destinationAirport?.replace(/\s/g, '-').toLowerCase()

        const elementSelector = ORIGINS_WHITELIST.includes(this.destinationAirport)
            ? `[data-warning="${destination}:${origin}"]`
            : `[data-warning="${destination}:all"]`

        let $warningElement = this.form.querySelector(elementSelector) ?? this.form.querySelector(`[data-warning="${destination}:all"]`)

        if (!$warningElement) return

        $warningElement.classList.add(this.warningElActiveClassName)
    }

}