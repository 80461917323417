const get = (url) => {
    var request = new XMLHttpRequest()
    return new Promise(function (resolve, reject) {
        request.onreadystatechange = function () {
            if (request.readyState !== 4) return

            if (request.status >= 200 && request.status < 300) {
                resolve(JSON.parse(request.responseText))
            } else {
                reject({
                    status: request.status,
                    statusText: request.statusText
                })
            }
        }

        request.open('GET', url, true)
        request.send()
    })
}

const post = (url, data) => {
    var request = new XMLHttpRequest()
    return new Promise(function (resolve, reject) {
        request.onreadystatechange = function () {
            if (request.readyState !== 4) return

            if (request.status >= 200 && request.status < 300) {
                resolve(JSON.parse(request.responseText))
            } else {
                reject({
                    status: request.status,
                    statusText: request.statusText
                })
            }
        }

        request.open('POST', url)
        request.setRequestHeader('Content-Type', 'application/json')
        request.send(JSON.stringify(data))
    })
}

const request = {
    get: get,
    post: post
}

export default request