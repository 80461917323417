import Handlebars from "handlebars/runtime"

Handlebars.registerHelper('format', function(context, format) {
    return context;
})

/**
 * These helpers are JavaScript versions of the Handlebars Java Conditional helpers.
 * You CAN NOT use arrows here as it screws up the this binding of the template
 * https://github.com/jknack/handlebars.java/blob/master/handlebars/src/main/java/com/github/jknack/handlebars/helper/ConditionalHelpers.java
 */
// Equals
Handlebars.registerHelper('eq', function (v1, v2, options) {
    if (!options) {
        return null
    }
    return (v1 == v2) ? options.fn(this) : options.inverse(this)
})

// Not equals
Handlebars.registerHelper('neq', function (v1, v2, options) {
    if (!options) {
        return null
    }
    return (v1 != v2) ? options.fn(this) : options.inverse(this)
})

// Greater than
Handlebars.registerHelper('gt', function(v1, v2, options) {
    return (v1 > v2) ? options.fn(this) : options.inverse(this)
})

// Greater than or equal to
Handlebars.registerHelper('gte', function(v1, v2, options) {
    return (v1 >= v2) ? options.fn(this) : options.inverse(this)
})

// Less than
Handlebars.registerHelper('lt', function(v1, v2, options) {
    return (v1 < v2) ? options.fn(this) : options.inverse(this)
})

// Less than or equal to
Handlebars.registerHelper('lte', function(v1, v2, options) {
    return (v1 <= v2) ? options.fn(this) : options.inverse(this)
})

// the size helper, due to JS sucking
Handlebars.registerHelper('size', array => array.length || 0)

Handlebars.registerHelper('pluralize', (number, singular, multiple) => {
  return (number === 1) ? `${singular}` : `${multiple}`
})