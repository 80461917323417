import flatpickr from "flatpickr"
import labelPlugin from 'flatpickr/dist/plugins/labelPlugin/labelPlugin'
import { Options, FlatpickrFn } from "flatpickr/dist/types"
import { addDays, isValid, isAfter, isBefore, isSameDay, isEqual, addMonths, addWeeks, addYears, startOfMonth, endOfMonth } from 'date-fns'
import parse from 'date-fns/parse'
import { EVENTS } from '../utils/constants'
import { LocationMetaData } from "../types/types"

interface DatePickerConfiguration {
    onSaleStart: Date
    onSaleEnd: Date
    availableDates?: Date[]
}

const tryParse = (maybeDate?: string): Date => {
    const ukLocale = parse(maybeDate, 'dd-MM-yyyy', new Date())
    const iso = parse(maybeDate, 'yyyy-MM-dd', new Date())
    return isValid(ukLocale) ? ukLocale : iso
}

const calculateRollingEndDate = (onSaleStart: Date, durationValue?: number, durationUnit?: string): Date | undefined => {
    if (durationUnit == null || durationUnit === "" || durationValue == null || !/^\d+$/.test(durationValue + "") || durationValue <= 0) {
        return undefined
    }
    switch (durationUnit) {
        case 'd':
            return addDays(onSaleStart, durationValue)
        case 'w':
            return addWeeks(onSaleStart, durationValue)
        case 'm':
            return addMonths(onSaleStart, durationValue)
        case 'y':
            return addYears(onSaleStart, durationValue)
        default:
            return undefined
    }
}

const createConfigurationFromAvailableDate = (availableDates: string[] = []): DatePickerConfiguration => {
    const parsedAvailableDates = availableDates
        .map(date => tryParse(date))
        .filter(date => isValid(date))
    const onSaleStart = parsedAvailableDates[0]
    const onSaleEnd = parsedAvailableDates[parsedAvailableDates.length - 1]
    return {
        onSaleStart: onSaleStart != null ? onSaleStart : startOfMonth(new Date()),
        onSaleEnd: onSaleEnd != null ? onSaleEnd : endOfMonth(new Date()),
        availableDates: parsedAvailableDates
    }
}

const createConfiguration = (
        onSaleStart?: string,
        onSaleEnd?: string,
        durationValue?: number,
        durationUnit?: string): DatePickerConfiguration => {

    const defaultOnSaleStart = addDays(new Date(), 3)
    const defaultOnSaleEnd = addMonths(new Date(), 24)

    const parsedOnSaleStart = tryParse(onSaleStart)
    const finalOnSaleStart = isValid(parsedOnSaleStart) && isAfter(parsedOnSaleStart, defaultOnSaleStart)
        ? parsedOnSaleStart
        : defaultOnSaleStart

    const parsedOnSaleEnd = tryParse(onSaleEnd)
    const rollingEndDate = calculateRollingEndDate(finalOnSaleStart, durationValue, durationUnit)
    const maybeOnSaleEnd = isValid(parsedOnSaleEnd) && isBefore(parsedOnSaleEnd, addMonths(new Date(), 36))
        ? parsedOnSaleEnd
        : defaultOnSaleEnd
    const finalOnSaleEnd = isValid(rollingEndDate) && isBefore(rollingEndDate, maybeOnSaleEnd)
        ? rollingEndDate
        : maybeOnSaleEnd

    return {
        onSaleStart: finalOnSaleStart,
        onSaleEnd: finalOnSaleEnd,
        availableDates: undefined
    }
}

export default class Datepicker {
    private containerElement: HTMLElement
    private dateElement: HTMLInputElement
    private uid: string
    private picker: FlatpickrFn

    constructor(container) {
        try {
            this.containerElement = container
            this.uid = container.dataset.uid
            this.dateElement = this.containerElement.querySelector('.sp-datepicker-input')

            const availableDatesElement: HTMLScriptElement | null = this.containerElement.querySelector(`#${this.uid}-availableDates`)
            const onSaleStart = this.containerElement.dataset.onSaleStart
            const onSaleEnd = this.containerElement.dataset.onSaleEnd

            const configuration = availableDatesElement != null
                ? createConfigurationFromAvailableDate(JSON.parse(availableDatesElement.innerText))
                : createConfiguration(onSaleStart, onSaleEnd)

            let config: Options = {
                animate: false,
                altInput: true,
                allowInput: true,
                altFormat: "D, d M Y",
                dateFormat: "d-m-Y",
                disableMobile: true,
                ignoredFocusElements: [window.document.body],
                static: true,
                inline: container.dataset != null && container.dataset.isInline != null,
                locale: {
                    firstDayOfWeek: 1
                },
                altInputClass: "",
                plugins: [new labelPlugin({})],
                onReady: () => {
                    let dateElement: HTMLInputElement = <HTMLInputElement>document.getElementById(this.uid)
                    if (dateElement) { dateElement.readOnly = true }
                },
                onOpen: () => {
                    document.body.classList.add('sp-datepicker-open')
                    container.querySelector(".flatpickr-calendar").addEventListener('click', (e: MouseEvent) => this.clickAway(e))
                },
                onClose: () => {
                    document.body.classList.remove('sp-datepicker-open')
                    container.querySelector(".flatpickr-calendar").removeEventListener('click', (e: MouseEvent) => this.clickAway(e))
                },
                minDate: configuration.onSaleStart,
                maxDate: configuration.onSaleEnd
            }

            if (configuration.availableDates != null) {
                config.disable = [date => !configuration.availableDates.some(enabledDate => isSameDay(date, enabledDate))]
            }

            this.picker = flatpickr(this.dateElement, config)

            this.init()
        } catch(e) {
            console.error('Datepicker cannot be initialised', e)
        }
    }

    public updateCalendarConfiguration(metadata: LocationMetaData): void {
        const configuration = createConfiguration(
            metadata.onSaleStart,
            metadata.onSaleEnd,
            metadata.durationValue,
            metadata.durationUnit)
        this.setConfigOption('minDate', configuration.onSaleStart)
        this.setConfigOption('maxDate', configuration.onSaleEnd)
        const currentDate = this.getDate()
        const isValidForStartDate = isEqual(currentDate, configuration.onSaleStart) || isAfter(currentDate, configuration.onSaleStart)
        const isValidForEndDate = isEqual(currentDate, configuration.onSaleEnd) || isBefore(currentDate, configuration.onSaleEnd)
        if (!isValidForStartDate || !isValidForEndDate) {
            const defaultCurrentDate = addDays(configuration.onSaleStart, 7)
            this.picker.setDate(isBefore(defaultCurrentDate, configuration.onSaleEnd) ? defaultCurrentDate : configuration.onSaleEnd)
        }
    }

    public setConfig(selected: Date, onSaleStart: Date, onSaleEnd: Date) {
        this.setConfigOption('minDate', onSaleStart)
        this.setConfigOption('maxDate', onSaleEnd)
        this.picker.setDate(selected)
    }

    public getDate(): Date {
        return this.picker.latestSelectedDateObj
    }

    public getSelectedDates(): Date[] {
        return this.picker.selectedDates
    }

    private setConfigOption(name: string, value: any) {
        try {
            this.picker.set(name, value)
        } catch(e) {
            console.error(`Could not set option: ${name} to ${value}`, e)
        }
    }

    private init() {
        this.picker.config.onChange.push((selectedDates) => {
            if (!selectedDates || !selectedDates[0]) { return }

            let dateChangeEvent: CustomEvent = new CustomEvent(EVENTS.DATE_CHANGED, {
                bubbles: true,
                detail: selectedDates[0]
            })
            this.containerElement.dispatchEvent(dateChangeEvent)
        })
    }

    private clickAway(e: MouseEvent) {
        let target = e.target as HTMLElement
        if (target.classList && target.classList.contains("open")) {
            this.picker.close()
        }
    }
}
