var Handlebars = require('handlebars/runtime')
var templates = require('../../../../../../../target/classes/templates/assets/search-templates')
import * as helpers from '../helpers'
import request from '../utils/request'
import { LocationsList, Location, LocationSelection } from '../types/types'
import { KEYS, EVENTS, DATE_FORMAT } from '../utils/constants'
import { mapLocationData } from '../utils/helpers'
import { isAfter, isBefore, isValid, addDays, addWeeks, addMonths, addYears } from 'date-fns'
import parse from 'date-fns/parse'

export default class Typeahead {
    private static OLD_RESPONSE = 'old response';

    private locations: LocationsList
    private containerElement: HTMLElement
    private prettyInputElement: HTMLInputElement
    private hiddenInputElement: HTMLInputElement
    private parentInputElement: HTMLInputElement
    private validationElement: HTMLElement
    private resultsElement: HTMLElement
    private errorMessageElement: HTMLElement
    private liveRegion: HTMLElement
    private resultsCloseButton: HTMLButtonElement
    private SELECTED_CLASS: string = "sp-selected"
    private OPEN_CLASS: string = "sp-open"
    private BOOKING_TYPE_ID: string
    private RESULT_SIZE: number = 4
    private BRAND: string
    private UID: string

    constructor(container: HTMLElement, brand: string, bookingTypeId: string) {
        console.log(helpers)
        try {
            // Set up DOM elements
            this.containerElement = container
            this.prettyInputElement = this.containerElement.querySelector('[data-typeahead-input]')
            this.hiddenInputElement = this.containerElement.querySelector('[data-typeahead-hidden-input]')
            this.parentInputElement = this.containerElement.querySelector('[data-typeahead-parent-input]')
            this.validationElement = this.containerElement.querySelector('[data-typeahead-validation]')
            this.resultsElement = this.containerElement.querySelector('[data-typeahead-results]')
            this.resultsCloseButton = this.containerElement.querySelector('[data-typeahead-results-close]')
            this.errorMessageElement = this.containerElement.querySelector('[data-typeahead-error-message]')
            this.liveRegion = this.containerElement.querySelector('[data-typeahead-live-region]')

            // Set up settings
            this.BOOKING_TYPE_ID = bookingTypeId

            // We want to show more results for car hire
            if(this.BOOKING_TYPE_ID === "5") {
                this.RESULT_SIZE = 10
            }

            this.UID = this.containerElement.dataset.uid
            this.BRAND = brand

            // Initialise
            this.init()
        } catch(e) {
            console.error('Typeahead cannot be initialised', e)
        }
    }

    get locationUrlDescription(): string {
        return this.hiddenInputElement.value
    }

    get parentName(): string {
        return this.parentInputElement.value
    }

    /*
    * Select a result
    */
    private selectItem(result: LocationSelection): void {
        let target: Location = this.locations.results.find(x => x.locationId === result.locationId)
        this.select(target)
    }

    public select(result: Location) {
        this.prettyInputElement.removeAttribute('aria-activedescendant')
        this.prettyInputElement.setAttribute("value", result.indexedDescription)
        this.hiddenInputElement.setAttribute("value", result.locationUrlDescription)
        if (this.parentInputElement) {
            this.parentInputElement.setAttribute("value", result.parentLocationUrlDescription || "")
            this.prettyInputElement.value = result.indexedDescription
            this.hiddenInputElement.value = result.locationUrlDescription
            this.parentInputElement.value = result.parentLocationUrlDescription || ""
        }

        this.setValidity(true)

        let selectEvent: CustomEvent = new CustomEvent(EVENTS.LOCATION_SELECT, {
            bubbles: true,
            detail: result
        })
        this.containerElement.dispatchEvent(selectEvent)

        this.close()
    }

    /*
    * Clear the inputs
    */
    public clear(): void {
        this.prettyInputElement.setAttribute("value", "")
        this.hiddenInputElement.setAttribute("value", "")
        if (this.parentInputElement) {
            this.parentInputElement.value = ""
            this.parentInputElement.setAttribute("value", "")
        }
        this.prettyInputElement.value = ""
        this.hiddenInputElement.value = ""

        this.liveRegion.innerHTML = ""
        this.setValidity(false)

        let clearedEvent: CustomEvent = new CustomEvent(EVENTS.LOCATION_CLEARED, {
            bubbles: true,
            detail: ''
        })
        this.containerElement.dispatchEvent(clearedEvent)
        this.close()
    }

    /*
    * Close the results list
    */
    public close(): void {
        this.prettyInputElement.removeAttribute('aria-activedescendant')
        this.prettyInputElement.setAttribute('aria-expanded', "false")
        this.resultsElement.innerHTML = ""
        this.liveRegion.innerHTML = ""
        this.containerElement.classList.remove(`${this.OPEN_CLASS}`)
        this.destroyClickAway()
    }

    private validate(isValid: boolean, errorMessage?: string): void {
        if (isValid) {
            this.validationElement.classList.add('valid')
            this.validationElement.classList.remove('invalid')
            this.validationElement.setAttribute('error-message', '')
            this.errorMessageElement.classList.remove('active')
            this.resultsElement.parentElement.classList.remove('invalid')
        } else {
            this.validationElement.classList.remove('valid')
            this.validationElement.classList.add('invalid')
            this.resultsElement.parentElement.classList.add('invalid')
            this.close()
            this.validationElement.setAttribute('error-message', errorMessage || 'There has been an error')

            if (errorMessage && errorMessage.length) {
              let errorContentEl: HTMLElement = this.errorMessageElement.querySelector('[data-typeahead-error-message-content]')
              errorContentEl.innerHTML = `${errorMessage}`
            }

            this.errorMessageElement.classList.add('active')
        }
    }

    /*
    * Validates the input
    */
    public setValidity(isValid: boolean, errorMessage?: string) {
        let defaultErrorMsg = (this.BRAND === '205') ? 'You must select a destination' : 'Please select a location'
        let errorMsg = isValid ? '' : (errorMessage || defaultErrorMsg)

        this.prettyInputElement.setCustomValidity(errorMsg)
        this.validate(isValid, errorMsg)
    }

    private init(): void {
        // Set up input listener
        this.prettyInputElement.addEventListener('input', (e: Event) => {
            this.setValidity(true)

            if (this.prettyInputElement.value.length >= 3) {
                this.setValidity(false)
                let q = this.prettyInputElement.value
                let query = `/locations-public-api/search/location?q=${q}&brand=${this.BRAND}&resultSize=${this.RESULT_SIZE}&bookingType=${this.BOOKING_TYPE_ID}&searchableOnly=true&withECMSId=false`

                // to resolve sync issues between input box value and the matches list,
                // this promise will reject all old requests,
                // by comparing the query value passed to the request
                // and the current value in the input box when the promise get resolved
                new Promise((resolve, reject) => {
                    request.get(query).then((data) => {
                        if (q === this.prettyInputElement.value) {
                            resolve(data);
                        } else {
                            reject(Typeahead.OLD_RESPONSE);
                        }
                    })
                })
                .then(this.clean.bind(this))
                .then(this.render.bind(this))
                .catch(error => {
                    if (error === Typeahead.OLD_RESPONSE) {
                        return;
                    }

                    console.error('Error in typeahead query', error);
                    this.setValidity(false);
                })
            } else {
                this.setValidity(false)
            }

            if (this.prettyInputElement.value.length === 0) {
                this.clear()
            }
        })

        this.prettyInputElement.addEventListener('click', (e: Event) => {
            let target = this.prettyInputElement
            target.setSelectionRange(0, target.value.length)
        })

        // Capture clicks inside the results and run select if target is a valid option
        this.resultsElement.addEventListener('click', (e: MouseEvent) => {
            let target: HTMLElement = (e.target as HTMLElement)
            let result: LocationSelection = {
                locationId: target.dataset.locationId
            }

            this.selectItem(result)
        })

        this.resultsElement.addEventListener('mouseenter', (e: MouseEvent) => {
            let selected: HTMLElement = this.resultsElement.querySelector(`.${this.SELECTED_CLASS}`)
            if (selected) {
                selected.classList.remove(this.SELECTED_CLASS)
                this.prettyInputElement.removeAttribute('aria-activedescendant')
            }
        })

        this.resultsCloseButton && this.resultsCloseButton.addEventListener('click', (e: MouseEvent) => {
          this.clear()
        })

        // Capture key events
        this.prettyInputElement.addEventListener("keydown", (event: KeyboardEvent) => {
            this.keyboardBindings(KEYS, event)
        })

        document.addEventListener(EVENTS.OPEN_DESTINATION_LIST, (e: CustomEvent) => {
            // close error tooltip when destination list open
            this.setValidity(true)
        })

        document.addEventListener(EVENTS.CLOSE_DESTINATION_LIST, (e: CustomEvent) => {
            // if there is no previously selected value, show error
            this.setValidity(this.hiddenInputElement.value && this.hiddenInputElement.value !== "")
        })
    }

    /*
    * Render the results list
    */
    private render(): void {
        if (!this.locations || (this.locations && this.locations.results && this.locations.results.length === 0)) {
            this.setValidity(false, 'No matches found')
        } else {
            this.setValidity(false); // since there are matches now, show "required" error
            let content = {
                results: this.locations,
                UID: this.UID
            }
            const template = Handlebars.templates['typeahead-results']
            const theCompiledHtml = template(content)
            const target: HTMLElement = this.resultsElement
            target.innerHTML = theCompiledHtml
            this.prettyInputElement.setAttribute('aria-activedescendant', `${this.UID}-typeahead-option-0`)
            this.prettyInputElement.setAttribute('aria-expanded', "true")
            this.containerElement.classList.add(`${this.OPEN_CLASS}`)
            this.liveRegion.innerHTML = `${this.locations.results.length} suggestions found. To navigate use up and down arrows and press enter key to select`
            this.clickAway()
        }
    }

    /*
    * Clean the locations-api response
    */
    private clean(data): void {
        this.locations = null; // empty matches list
        if (data.items.length > 0) {
            let results = data.items.map(mapLocationData).filter(location => {
              const onSaleStart = this.getOnSaleStart(location)
              const onSaleEnd = this.getOnSaleEnd(location)
              return isAfter(onSaleEnd, addDays(new Date(), 2)) && isAfter(onSaleEnd, onSaleStart)
            })

            let cleaned: LocationsList = {
                results: results.sort((a, b) => a.locationTypeId - b.locationTypeId)
            }

            this.locations = cleaned
        }
    }

    private getOnSaleStart(location: Location): Date {
        const onSaleStart = this.parseDate(location?.locationMetadata?.onSaleStart, addDays(new Date(), 3))
        return isValid(onSaleStart) && isAfter(onSaleStart, addDays(new Date(), 3)) ? onSaleStart : addDays(new Date(), 3)
    }

    private getOnSaleEnd(location: Location): Date {
        const rollingEndDate = this.calculateRollingEndDate(location)
        const onSaleEnd = location?.locationMetadata?.onSaleEnd
        const onSaleEndDate = this.parseDate(onSaleEnd, addYears(new Date(), 2))
        return rollingEndDate != null && isAfter(onSaleEndDate, rollingEndDate) ? rollingEndDate : onSaleEndDate
    }

    private parseDate(value: string, defaultValue: Date): Date {
        return /^\d{4}-\d{2}-\d{2}$/.test(value) ? parse(value, DATE_FORMAT.PARSING, new Date()) : defaultValue
    }

    private calculateRollingEndDate(location: Location): Date | undefined {
        const durationUnit = location?.locationMetadata?.durationUnit
        const durationValue = location?.locationMetadata?.durationValue
        if (durationUnit == null || durationUnit === "" || durationValue == null || !/^\d+$/.test(durationValue + "") || durationValue <= 0) {
            return undefined
        }
        const onSaleStart = this.getOnSaleStart(location)
        switch (durationUnit) {
            case 'd':
                return addDays(onSaleStart, durationValue)
            case 'w':
                return addWeeks(onSaleStart, durationValue)
            case 'm':
                return addMonths(onSaleStart, durationValue)
            case 'y':
                return addYears(onSaleStart, durationValue)
            default:
                return undefined
        }
    }

    /*
    * Move the selected option down a sibling
    */
    private moveDown(): void {
        let selected: HTMLElement = this.resultsElement.querySelector(`.${this.SELECTED_CLASS}`)
        this.prettyInputElement.removeAttribute('aria-activedescendant')
        if (selected && selected.nextElementSibling) {
            selected.classList.remove(this.SELECTED_CLASS)
            selected.nextElementSibling.classList.add(this.SELECTED_CLASS)
            this.prettyInputElement.setAttribute('aria-activedescendant', selected.nextElementSibling.id)
        }
    }

    /*
    * Move the selected option up a sibling
    */
    private moveUp(): void {
        let selected: HTMLElement = this.resultsElement.querySelector(`.${this.SELECTED_CLASS}`)
        this.prettyInputElement.removeAttribute('aria-activedescendant')
        if (selected && selected.previousElementSibling) {
            selected.classList.remove(this.SELECTED_CLASS)
            selected.previousElementSibling.classList.add(this.SELECTED_CLASS)
            this.prettyInputElement.setAttribute('aria-activedescendant', selected.previousElementSibling.id)
        }
    }

    /*
    * Bind and react to keyboard events
    */
    private keyboardBindings(keys, event: KeyboardEvent) {
        switch (event.which) {
            case keys.ESC:
                this.close()
            break
            case keys.DOWN:
            case keys.RIGHT:
                this.moveDown()
            break
            case keys.UP:
            case keys.LEFT:
                this.moveUp()
            break
            case keys.TAB:
            case keys.RETURN:
                let selectedItem: HTMLElement = this.resultsElement.querySelector(`.${this.SELECTED_CLASS}`)

                if (selectedItem) {
                    let result: LocationSelection = {
                        locationId: selectedItem.dataset.locationId
                    }

                    this.selectItem(result)
                    event.preventDefault()
                }
            break
            default:
                return
        }
    }

    private clickAway() : void {
        document.addEventListener('click', (e) => this.addClickAway(e))
    }

    private destroyClickAway(): void {
        document.removeEventListener('click', (e) => this.addClickAway(e))
    }

    private addClickAway(e: Event) : void {
        let target: any = e.target
        const closest: HTMLElement = target.closest('sp-typeahead-results-wrapper')
        if (closest != undefined) return
        this.close()
    }
}